.table-responsive-wrap {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.table {
	letter-spacing: 0px;
	label {
		margin: 0px;
		min-height: 12px;
	}
	th {
		&.check-block {
			.dropdown-checkbox {
				// padding: 0px 6px;
				padding: 0px 0px;
				.btn-checkbox {
					.checkWrap {
						input {
							left: 0;
						}
					}
				}
				.btn-link {
					padding: 0px 0px;
					border-radius: 0px;
					border: 0px;
					color: #114699;
				}
				&:hover {
					background: transparent;
				}
				.btn-split {
					&:after {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				.dropdown-menu {
					-webkit-box-shadow: 0 0 3px 0 rgba(82, 63, 105, 0.15);
					box-shadow: 0 0 15px 0 rgba(82, 63, 105, 0.15);
					border-radius: 4px;
					background-color: #ffffff;
					padding: 0px;
					left: -22px !important;
					.dropdown-item {
						-webkit-transition: all 0.3s;
						transition: all 0.3s;
						letter-spacing: 0px;
						font-size: 14px;
						padding: 6px 24px;
						vertical-align: middle;
						svg {
							color: #6c7293;
						}
						span {
							color: #6c7293;
							font-weight: 400;
							margin-left: 18px;
						}
						&:hover {
							color: #000;
							background-color: #cccccc;
							svg,
							span {
								color: #000;
							}
						}
					}
				}
			}
		}
		border: none;
		vertical-align: inherit;
	}
	thead {
		&.sticky-thead {
			th {
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				background: #f3f3f3;
				z-index: 9;
				color: #232323;
				font-weight: 700;
			}
		}
		th {
			color: #232323;
			font-weight: 500;
			font-size: 15px;
			padding: 8px;
			vertical-align: middle;
			border-bottom: 1px solid #cbcdd0;
			@media (max-width: 1440px) {
				font-size: 14px;
			}
		}
		.width-th-100 {
			@media (max-width: 1380px) {
				width: 100px;
			}
		}
	}
	thead tr {
		background-color: #f3f3f3;
		box-shadow: 0px 3px 6px -2px #2a2b2f63;
	}
	tbody tr {
		tbody tr &:nth-child(odd) {
			background-color: transparent;
		}
		&:nth-child(even) {
			background-color: #fff;
		}
		&.no-hover-row {
			&:hover {
				background-color: #fff;
			}
		}
		td {
			vertical-align: middle;
			border: none;
			color: #6c7293;
			border-top: 1px solid #c7c7c7;
			padding: 8px;
			word-break: break-word;
			white-space: nowrap;
			@media (max-width: 1440px) {
				font-size: 14px;
			}
			@media (max-width: 1330px) {
				font-size: 13px;
			}
			.image-column {
				width: 10px;
				height: 40px;
				flex: 0 0 80px;
				margin: auto;
				position: relative;
				img {
					max-width: 100%;
					max-height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.price-column {
				font-size: 15px;
				font-weight: 500;
				font-family: 'Roboto', sans-serif;
				color: #8bc34a;
			}
			.action-wrap {
				color: #93a2dd;
				width: 35px;
				height: 35px;
				border-radius: 4px;
				position: relative;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 5px;
				transition: all 0.3s;
				background: transparent;
				&:hover,
				&:focus {
					color: #5d78ff;
					text-decoration: underline;
					background: #e0e7fc;
				}
			}
			.badge {
				min-width: 80px;
				font-size: 11px;
				line-height: 1.5;
				font-weight: 500;
				border-radius: 4px;
			}
			.active-badge {
				background-color: rgba(29, 201, 183, 0.18);
				color: #1dc9b7;
			}
			.close-badge {
				background-color: rgba(253, 57, 122, 0.18);
				color: #fd397a;
			}
			.delay-badge {
				background-color: rgba(255, 184, 34, 0.18);
				color: #ffb822;
			}
			.info-badge {
				background-color: rgba(85, 120, 235, 0.18);
				color: #5578eb;
			}
			.dropdown-manu {
				.btn-primary {
					&:after {
						display: none;
					}
				}
				.dropdown {
					&.show {
						.action-wrap {
							color: #5d78ff;
							text-decoration: underline;
							background: #f0f3ff;
						}
					}
				}
				.dropdown-menu {
					-webkit-box-shadow: 0 0 3px 0 rgba(82, 63, 105, 0.15);
					box-shadow: 0 0 15px 0 rgba(82, 63, 105, 0.15);
					border-radius: 4px;
					background: #fff;
					.dropdown-item {
						-webkit-transition: all 0.3s;
						transition: all 0.3s;
						letter-spacing: 0px;
						font-size: 14px;
						padding: 6px 24px;
						vertical-align: middle;
						svg {
							color: #a2a5b9;
						}
						span {
							color: #595d6e;
							font-weight: 400;
							margin-left: 18px;
						}
						&:hover {
							background-color: #f7f8fa;
							svg,
							span {
								color: #5d78ff;
							}
						}
					}
				}
			}
		}
	}
	&.table-hover {
		tbody {
			tr {
				&:hover {
					color: #808080;
					background-color: rgba(80, 110, 228, 0.04);
				}
			}
		}
	}
	.table-checkbox-width {
		width: 35px;
	}
}
.pagination {
	.page-item {
		.page-link {
			border-radius: 50% !important;
			border: none;
			width: 35px;
			padding: 0px;
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0px 5px;
			color: #565962;
		}
		&.active {
			span.page-link {
				background: #00bece;
				border-color: #00bece;
				color: #fff;
			}
		}
		&.pagination-next a {
			background: #ecedf2;
			color: #8d9cd3;
			font-size: 26px;
			span {
				margin-top: -5px;
			}
		}
		&.pagination-prev a {
			background: #f4f5f9;
			color: #d4d9ec;
			font-size: 26px;
			span {
				margin-top: -5px;
			}
		}
	}
}
.table-pagination-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 15px 15px;
	@media (max-width: 575px) {
		flex-direction: column;
		justify-content: center;
	}
	.dropdown button {
		border-radius: 4px;
		background: transparent;
		color: #74788d;
		border: 1px solid #e8ecfa;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 80px;
		font-size: 14px;
		padding: 7px 15px;
		&:hover {
			color: #fff;
			background: #5d78ff;
			border-color: #5d78ff;
		}
	}
	.pagination {
		margin-bottom: 0px;
	}
}
.records-filter {
	display: flex;
	align-items: center;
	justify-content: center;
	.records-number {
		margin-left: 0px;
		font-weight: 400;
		color: #8f94a9;
		font-size: 14px;
		letter-spacing: 0px;
		@media (max-width: 575px) {
			margin: 15px 0 0 !important;
		}
	}
}

.status-dropdown-wrap {
	.btn {
		border-radius: 30px;
		padding: 7px 10px;
		font-weight: normal;
		min-width: 95px;
		margin: 0px;
		min-height: 12px;
		font-size: 82%;
		color: #fff;
		line-height: 1;
		// width: 86px;
		border: none;
		font-size: 12px;
		line-height: 1.5;
		font-weight: 500;
		border-radius: 4px;
		&:hover,
		&:focus {
			box-shadow: none;
			text-decoration: none;
		}
	}
	&.success {
		.btn {
			background-color: rgba(29, 201, 183, 0.18);
			color: #1dc9b7;
		}
	}
	&.danger {
		.btn {
			background-color: rgba(253, 57, 122, 0.18);
			color: #fd397a;
		}
	}
	&.warning {
		.btn {
			background-color: rgba(255, 184, 34, 0.18);
			color: #ffb822;
		}
	}
	&.inprogress {
		.btn {
			background-color: rgba(85, 120, 235, 0.18);
			color: #5578eb;
		}
	}
	.dropdown-menu {
		min-width: 8rem;
		.dropdown-item {
			margin: 2px 0;
			color: #a5a6ad;
			position: relative;
			transition: all 0.3s;
			letter-spacing: 0px;
			font-size: 14px;
			padding: 6px 24px;
			vertical-align: middle;
			&:hover,
			&:focus {
				color: #e8e8e8;
				background-color: #0c1822;
			}
		}
	}
}

.badge {
	font-size: 11px;
	line-height: 1.5;
	font-weight: 500;
	border-radius: 4px;
	padding: 7px 15px;
	&.badge-success {
		background-color: rgba(29, 201, 183, 0.18);
		color: #1dc9b7;
	}
	&.badge-danger {
		background-color: rgba(253, 57, 122, 0.18);
		color: #fd397a;
	}
	&.badge-warning {
		background-color: rgba(255, 184, 34, 0.18);
		color: #ffb822;
	}
	&.badge-info,
	&.badge-inprogress {
		background-color: rgba(85, 120, 235, 0.18);
		color: #5578eb;
	}
}

// .badge {
// 	border-radius: 3px;
// 	padding: 7px 15px;
// 	font-weight: normal;
// 	min-width: 70px;
// 	font-size: 82%;
// }
// .badge-success {
// 	color: #fff;
// 	background-color: #34bea4;
// }
// .badge-danger {
// 	color: #fff;
// 	background-color: #eb5757;
// }
// .badge-warning {
// 	color: #fff;
// 	background-color: #ffc300;
// }
// .badge-inprogress {
// 	color: #fff;
// 	background-color: #716aca;
// }
.full-information-colum {
	display: flex;
	align-items: flex-start;
	.info-title {
		font-size: 15px;
		color: #232323;
		font-weight: 700;
		cursor: pointer;
		line-height: 22px;
		&:hover {
			color: #114699;
		}
		&.not-hover {
			&:hover {
				color: #333;
				cursor: default;
			}
		}
	}
}
.text-blue {
	color: #0055ff;
}
.info-column {
	letter-spacing: 0px;
	width: 270px;
	margin-left: 20px;
	flex: 1;
	.text-bold {
		color: #343a40;
		font-weight: 500;
	}
	p {
		font-size: 14px;
		line-height: 22px;
	}
	.info-title {
		font-size: 14px;
		color: #232323;
		font-weight: 700;
		cursor: pointer;
		line-height: 16px;
		&:hover {
			color: #114699;
		}
		&.not-hover {
			&:hover {
				color: #114699;
				cursor: default;
			}
		}
	}
	.text-ellipsis {
		width: 210px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.fa-user {
		margin-left: 2px;
	}
}
.description-column {
	letter-spacing: 0px;
	width: 206px;
}
.description1-column {
	letter-spacing: 0px;
	width: 155px;
}
.amenities-column {
	width: 250px;
	.no-amenities {
		.added-btn {
			font-size: 13px;
			color: #343a40;
			font-weight: 500;
			cursor: pointer;
			&:hover {
				color: #3856ff;
			}
		}
	}

	.amenities-badge {
		color: #172533;
		background: #f3f3f3;
		padding: 4px 6px;
		min-width: auto;
		font-size: 11px;
		line-height: 1.5;
		font-weight: 500;
		border-radius: 4px;
		text-align: center;
		white-space: nowrap;
		display: inline-block;
	}
}
.slot-column {
	letter-spacing: 0px;
	.text-dark {
		color: #d3d3d3;
		font-weight: 500;
	}
	p {
		font-size: 14px;
	}
}

.action-buttons {
	// display: flex;
	// align-items: center;
	// justify-content: center;
	margin: 10px 0;
	.btn-icon {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 0;
		color: #b4b8cd;
		font-size: 14px;
		border-radius: 4px;
		cursor: pointer;
		margin: 0 10px;

		&:hover {
			background: #ffffff;
			color: #08121c;
		}
	}
	// .action-buttons{
	// 	background:
	// }
}
.table-action-dropdown {
	.dropdown-menu {
		min-width: 8rem;
		.dropdown-item {
			font-size: 14px;
			padding: 5px 15px;
			margin: 2px 0;
			color: #8386a7;
			position: relative;
			padding-left: 40px;
			&:hover,
			&:focus {
				color: #5d78ff;
				background: #f7f8fa;
			}
			.svg-inline--fa {
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
.header-actions {
	.action-drop {
		.dropdown-menu {
			min-width: 8rem;
			.dropdown-item {
				font-size: 14px;
				padding: 5px 15px;
				margin: 2px 0;
				color: #8386a7;
				position: relative;
				padding-left: 40px;
				&:hover,
				&:focus {
					color: #5d78ff;
					background: #f7f8fa;
				}
				.svg-inline--fa {
					position: absolute;
					left: 15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.view-details-link {
	text-decoration: underline;
	color: #506ee4;
	cursor: pointer;
}

.table-header-actions {
	display: flex;
	align-items: center;
	@media (max-width: 546px) {
		display: block;
	}
	.search-input-wrap {
		position: relative;
		margin-right: 15px;
		@media (max-width: 767px) {
			margin-right: 10px;
		}
		@media (max-width: 630px) {
			margin-right: 5px;
		}
		.form-control {
			width: 250px;
			height: 38px;
			border: 1px solid #c7c7c7;
			padding-right: 30px;
			transition: all 0.3s ease;
			&:focus {
				border-color: #8ca3f1 !important;
			}
			@media (max-width: 991px) {
				width: 180px;
				font-size: 12px;
			}
			@media (max-width: 767px) {
				width: 180px;
				font-size: 12px;
			}
			@media (max-width: 630px) {
				width: 130px;
			}
			@media (max-width: 546px) {
				width: 100%;
				margin: 10px 0;
			}
			&::placeholder {
				font-size: 14px;
			}
		}
		.search-icon {
			position: absolute;
			right: 8px;
			top: 4px;
		}
		.booking-search-icon {
			position: absolute;
			right: 8px;
			top: 9px;
		}
	}
	.add-new-btn {
		width: auto;
		padding: 9px 20px;
		background: #101010;
		color: #fff;
		border-radius: 5px;
		border: 0px;
		transition: all 0.3s ease;
		font-size: 14px;
		font-weight: 500;
		text-decoration: none;
		@media (max-width: 767px) {
			font-size: 12px;
			padding: 10px 20px;
		}
		&:hover {
			text-decoration: none;
			background: #114699;
			-webkit-box-shadow: -1px 3px 8px 3px rgba(0, 0, 0, 0.14);
			box-shadow: -1px 3px 8px 3px rgba(0, 0, 0, 0.14);
		}
	}
}

.loading-div {
	min-height: 200px;
}

.class-table {
	@media (max-width: 1100px) {
		width: 1160px;
	}
}
.customer-table {
	@media (max-width: 1100px) {
		width: 1100px;
	}
}
.event-table {
	@media (max-width: 991px) {
		width: 931px;
	}
}
.subscription-table {
	@media (max-width: 991px) {
		width: 931px;
	}
}
// ==For Responsive S========
@media (max-width: 991px) {
	.page-header-wrap {
		.page-header {
			.heading-text {
				font-size: 18px;
			}
			.page-title {
				svg {
					margin-right: 8px;
					width: 15px;
				}
			}
		}
	}
	.modal-table-responsive {
		z-index: 9999;
		.modal-lg,
		.modal-xl {
			max-width: 98%;
		}
		.modal-footer {
			padding-bottom: 50px;
		}
	}
}
@media (max-width: 767px) {
	.page-header-wrap {
		.page-header {
			.heading-text {
				font-size: 16px;
			}
		}
	}
	.table-header-actions {
		.add-new-btn {
			padding: 9px 10px;
		}
	}
	.card {
		.card-header {
			padding: 15px;
		}
	}
	.table {
		thead {
			tr {
				background-color: transparent;
				box-shadow: none;
				th {
					border-bottom: 0;
					padding: 6px 15px;
				}
			}
		}
		tbody {
			tr {
				td {
					border-top: 1px solid #efefef;
					&:last-child {
						border-bottom: 1px solid #efefef;
					}
					.full-information-colum {
						padding: 10px;
						.image-column {
							margin: 0;
							width: 50px;
							height: 50px;
							flex: 0 0 50px;
							img {
								width: 50px;
								height: 50px;
								position: static;
								transform: translate(0);
								border-radius: 50%;
							}
						}
					}
					.price-column {
						font-size: 14px;
					}
				}
			}
		}
	}
	.modal-table-responsive {
		.img-align-title {
			margin-top: 28px !important;
		}
		.title-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 5px;
		}
		.check-title-wrappper {
			margin-bottom: 0;
		}
	}
	.check-title-wrappper {
		display: flex;
		align-items: flex-start;
		margin-bottom: 8px;
		span {
			color: #000;
		}
	}
	.info-title {
		color: #000;
		margin: 0 0 0 8px !important;
		line-height: normal !important;
	}
	.title-label {
		color: #000;
		font-weight: 600;
		margin-right: 5px;
	}
	.title-value {
		color: #999;
	}
	.info-column {
		width: 100%;
		flex: auto;
		.text-ellipsis {
			width: auto;
			white-space: normal;
		}
		.text-bold {
			color: #000;
			font-weight: 600;
		}
		p {
			color: #999;
			white-space: normal;
		}
		.info-title {
			color: #000;
		}
	}
	.btn-wrapper {
		display: flex;
		// justify-content: flex-end;
		align-items: center;
		margin: 12px 0 0;
		.action-buttons {
			margin: 0;
			.btn-icon {
				margin: 0 0 0 20px;
				svg {
					margin-right: 5px;
				}
			}
		}
	}
	.action-buttons.btn-wrapper {
		margin: 0;
		.btn-icon {
			margin: 0 0 0 20px;
			svg {
				margin-right: 5px;
			}
		}
	}
	.records-filter {
		.records-number {
			margin-top: 0 !important;
		}
	}
	.custom-nav-tabs {
		.nav-item {
			width: 49.8%;
			margin: 0 0 1px 1px;
			.nav-link {
				margin-bottom: 0;
			}
		}
	}
	.session-category-wrapper {
		padding: 15px !important;
	}
	.category-description {
		margin-bottom: 0;
		padding-left: 38px;
		margin-top: 4px;
	}
	.staff-duration-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 5px;
		p {
			margin-right: 12px;
		}
	}
	.category-item-wrapper {
		margin-bottom: 12px;
		.first-caps {
			margin-right: 10px;
			line-height: 1.7;
		}
	}
	.view-all-cate {
		color: #000;
		font-weight: 600;
	}
	.class-table,
	.event-table,
	.customer-table {
		width: 100%;
	}
	.mb-sp-15 {
		margin-bottom: 15px !important;
	}
	.card {
		.card-title {
			font-size: 16px;
		}
	}
	.table-title {
		font-size: 20px !important;
		font-weight: 600 !important;
		color: #000 !important;
		padding: 0 0 10px 20px !important;
	}
	.booking-filter {
		margin-bottom: 10px;
		border: 0 !important;
	}
	.booking-tabel-sp {
		padding: 12px 20px !important;
		.check-title-wrappper {
			span {
				font-size: 13px;
				line-height: 1.5;
			}
			.link-text,
			.activity-text {
				font-size: 14px;
				font-weight: 700;
				margin-left: 8px;
			}
		}
		p {
			color: #999;
			svg {
				color: #000;
			}
		}
		.text-dark,
		.text-bold {
			font-weight: 600;
			font-size: 14px;
			line-height: 22px;
			color: #000 !important;
		}
	}
	.tooltip-wrapper {
		.table-responsive-wrap {
			padding-top: 0;
		}
	}
	.service-box-wrapper {
		box-shadow: none !important;
		.service-haeder {
			padding: 12px 20px;
			border-bottom: 0 !important;
		}
		.service-list-wrapper {
			.service-used-list {
				td {
					border-color: #efefef !important;
					&:last-child {
						text-align: left !important;
						width: auto !important;
					}
				}
			}
		}
	}
	.table-pagination-wrap {
		display: block;
		.rc-pagination {
			margin-bottom: 15px;
			.rc-pagination-item {
				font-size: 14px;
			}
		}
	}
	.image-column.modal-user-img.img-align-title {
		width: 50px !important;
	}
	.modal-table-responsive {
		.table {
			margin-bottom: 0;
			tbody {
				tr {
					td {
						padding: 0;
					}
					&:first-child {
						td {
							border: 0;
						}
					}
					&:last-child {
						td {
							border: 0;
						}
					}
				}
			}
		}
	}
	.no-result-block {
		min-height: 260px !important;
		.empty-ico {
			img {
				width: 110px;
			}
		}
		h5 {
			font-size: 18px !important;
		}
		.no-service-data {
			border-top: 0 !important;
		}
	}
	.ml-20 {
		margin-left: 20px;
	}
	.mb-sp-5 {
		margin-bottom: 5px !important;
	}
}
@media (max-width: 575px) {
	.title-wrapper {
		flex-wrap: wrap;
		margin-bottom: 0px;
	}
	.check-title-wrappper {
		margin-top: 10px;
		order: 1;
	}
	.modal-table-responsive {
		.check-title-wrappper {
			margin-top: 0;
			width: 100%;
		}
		.title-wrapper {
			> div {
				width: 100%;
				p {
					margin-bottom: 5px !important;
					text-align: right;
				}
			}
		}
	}
	.staff-title {
		margin-bottom: 0;
		.check-title-wrappper {
			margin-top: 0;
			order: 0;
		}
		> div {
			p {
				text-align: left;
				line-height: 22px;
				margin-top: 4px;
			}
		}
	}
	.staff-duration-wrapper {
		flex-wrap: wrap;
		margin-bottom: 0;
		> p {
			margin-bottom: 5px !important;
		}
	}
	.img-align-title {
		margin-top: 10px !important;
	}
	.price-column {
		// font-size: 13px !important;
	}
	.sub-title {
		font-size: 16px;
	}
	.search-filed {
		margin-top: 10px;
		width: 100% !important;
	}
}
@media (max-width: 568px) {
	.page-header {
		.page-title {
			margin-bottom: 6px;
		}
		.breadcrumb {
			font-size: 14px;
			.breadcrumb-item {
				a {
					.breadcrumb-icon {
						img {
							width: 14px;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 546px) {
	.table-header-actions {
		text-align: right;
	}
	.custom-nav-tabs {
		.nav-item {
			width: 100%;
			margin: 0 0 1px;
		}
	}
	.mob-card {
		.card {
			.custom-card-header {
				.custom-reactselect {
					min-width: 100%;
					margin-top: 12px;
				}
			}
			.card-title {
				display: block;
			}
		}
	}
}
@media (max-width: 450px) {
	.page-header {
		.breadcrumb {
			font-size: 13px;
		}
	}
	.table {
		tbody {
			tr {
				td {
					.full-information-colum {
						padding: 8px 3px;
						width: 240px;
						.image-column {
							height: 40px;
							width: 40px;
							flex: 0 0 40px;
						}
					}
				}
			}
		}
	}
	.session-category-wrapper {
		padding: 12px 10px !important;
	}
	.category-description {
		padding-left: 30px;
	}
	.info-column {
		margin-left: 12px;
		.checkWrap {
			padding-left: 15px;
			.checkmark {
				height: 14px;
				width: 14px;
			}
		}
		p {
			font-size: 13px;
			line-height: 18px;
		}
	}
	.info-title {
		margin: 0 0 0 3px !important;
		font-size: 13px !important;
	}
	.title-label {
		margin-right: 3px;
	}
	.btn-wrapper {
		.action-buttons {
			.btn-icon {
				margin: 0 0 0 10px;
			}
		}
	}
	.staff-duration-wrapper {
		display: block;
		p {
			margin-right: 0;
		}
	}
	.image-column.modal-user-img.img-align-title {
		width: 40px !important;
	}
	.modal-table-responsive {
		.img-align-title {
			margin-top: 26px !important;
		}
	}
}
// ==For Responsive E========
