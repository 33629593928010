a {
	outline: none;
}
.navbar.navbar-for-landing {
	background: transparent;
	transition: 0.5s ease-in-out;
}
.navbar.navbar-for-landing.header-fixed {
	background: #fff;
	box-shadow: 0 0 6px -2px #0000006b;
}
.navbar-brand img {
	width: 120px;
}
.color-gray {
	color: #8a8686;
}
.form-wrapper-radio-box .custom-invalid-feedback {
	left: 0;
}
.banner {
	background-position: center;
	// background: url('../../assets/img/banner.jpg');
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.banner:before {
	position: absolute;
	top: 0;
	left: 0;
	content: '';
	background-color: rgba(0, 0, 0, 0.51);
	width: 100%;
	height: 100%;
}
.banner-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
.banner-content {
	h1 {
		font-size: 50px;
		color: #fff;
		line-height: 38px;
	}
}
.banner-content h3:after {
	position: absolute;
	width: 100px;
	height: 2px;
	background: #fff;
	content: '';
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
	text-align: center;
}
.center-logo-wrap img {
	width: 260px;
}
.navbar-dark .navbar-nav .nav-link {
	color: #fff !important;
	padding: 10px 15px !important;
	font-weight: 400;
}
.navbar-dark .navbar-nav .nav-link.active {
	font-weight: 600;
}
.navbar-dark .navbar-nav .nav-link span {
	position: relative;
}
.navbar-dark .navbar-nav .nav-link span:after {
	background: #fff;
	height: 2px;
	width: 100%;
	bottom: -6px;
	content: '';
	position: absolute;
	left: 0;
	opacity: 0;
	transition: all 300ms ease-in-out;
}
.navbar-dark .navbar-nav .nav-link.active span:after,
.navbar-dark .navbar-nav .nav-link:hover span:after {
	opacity: 1;
}

.home-page .explore-section {
	padding: 120px 0 160px;
}
.home-page .join-section {
	padding: 70px 0 70px;
}
.home-page .explore-card {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0px 1px 8px 1px #d0d0d0;
	text-align: center;
	height: 100%;
}
.home-page .explore-card p {
	color: #8a8686;
	margin: 0;
	margin: 0;
	font-size: 14px;
}
.home-page .explore-card-content {
	padding: 30px 15px;
}
.home-page .explore-card-content h4 {
	position: relative;
	margin-bottom: 20px;
	font-size: 18px;
}
.home-page .explore-card-content h4:after,
.main-heading-wrap h2:after {
	position: absolute;
	left: 50%;
	content: '';
	width: 30px;
	height: 2px;
	background-color: #000;
	transform: translate(-50%, -50%);
	text-align: center;
	bottom: -9px;
}
.home-page .explore-card-img {
	height: 220px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}
.about-img {
	width: 100%;
}
.about-section .container-fluid {
	padding: 0;
	background: #fff;
}
.about-section .container-fluid .row {
	margin: 0;
	align-items: center;
}
.no-padding-left {
	padding-left: 0 !important;
}
.no-padding-right {
	padding-right: 0 !important;
}
.explore-slider {
	padding: 0px 15px;
	margin: 15px 0;
}
.about-content-wrap {
	position: relative;
}
.about-content {
	max-width: 80%;
	margin: 0 auto;
	p {
		line-height: 30px;
		font-size: 16px;
	}
}
.join-section {
	background-color: #f1f1f1;
}
.join-section-btn-wrap a {
	display: inline-block;
	margin: 20px 0;
}
.social-icons,
.footer-nav {
	padding: 0;
	list-style: none;
	margin: 10px 0;
}
.footer-nav {
	text-align: right;
}
.social-icons li,
.footer-nav li {
	display: inline-block;
}
.social-icons li a,
.footer-nav li a {
	margin: 0 10px;
	color: #000;
}
.social-icons li a:hover .fa-facebook-f {
	color: #3b5999;
}
.social-icons li a:hover .fa-linkedin-in {
	color: #0077b5;
}
.social-icons li a:hover .fa-twitter {
	color: #55acee;
}
.social-icons li a:hover .fa-instagram {
	color: #e4405f;
}
.center-logo-wrap h1 {
	color: #fff;
	font-size: 120px;
	font-family: 'Josefin Sans', sans-serif;
	margin: 0;
	line-height: 100px;
}
.about-img-wrap img {
	width: 100%;
}
.footer-nav a,
.footer-nav a:hover {
	text-decoration: none;
	color: #000;
	font-weight: 500;
}
.explore-section .main-heading-wrap {
	margin-bottom: 20px;
}
/*media Start From here*/
// ====For Match from figma (Screen Size 1440px) S===============
@media (min-width: 1440px) and (max-width: 1440px) {
	.navbar-brand {
		img {
			width: 112px;
		}
	}
	.navbar-dark {
		.navbar-nav {
			.nav-link {
				font-weight: 600;
				line-height: 23px !important;
				letter-spacing: 1.4px;
				&:last-child {
					padding-right: 30px !important;
				}
			}
		}
	}
}
// ====For Match from figma (Screen Size 1440px) E===============
@media (max-width: 1199px) {
	.banner {
		min-height: 540px;
	}
	.banner-content {
		top: 50%;
		right: auto;
		left: 0;
		width: 100%;
		max-width: 100%;
		padding: 0 30px;
	}
	.center-logo-wrap h1 {
		text-align: center;
	}
	.banner-content h3 {
		text-align: center;
	}
	.about-content {
		position: initial;
		transform: none;
		padding: 0 20px;
	}
	.about-section {
		padding-bottom: 120px;
	}
}
@media (max-width: 767px) {
	.center-logo-wrap h1 {
		font-size: 56px;
		line-height: 50px;
	}
	.banner-content h3 {
		font-size: 18px;
		margin: 0;
	}
	.navbar-brand img {
		width: 80px;
		margin-left: 10px;
		/* 	margin-left: 20px; */
	}
	.about-content {
		position: initial;
		transform: none;
		padding: 0;
		margin-bottom: 40px;
		max-width: 100%;
		padding-top: 30px;
	}
	.about-section .no-padding-left {
		padding: 0px !important;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.about-section .no-padding-right {
		padding-right: 15px !important;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.about-section .container-fluid .row {
		flex-flow: column-reverse;
	}
	.about-section {
		padding-bottom: 0px;
	}
	.explore-section .container {
		max-width: 100%;
	}
}
@media (max-width: 767px) {
	.navbar-collapse.collapse,
	.navbar-collapse.collapsing {
		margin: 0 -15px !important;
	}
	.center-logo-wrap h1 {
		font-size: 56px;
		line-height: 58px;
	}
	.banner-content h3 {
		font-size: 18px;
		margin: 0;
	}
	.masonry-gallery-wrap {
		column-count: 1;
		-webkit-column-count: 1;
	}

	.social-icons,
	.footer-nav {
		text-align: center;
	}
	.home-page .explore-section {
		padding: 60px 0;
	}
}
@media (max-width: 540px) {
	.round-modal-wrap .modal-content {
		width: auto;
		height: auto;
		border-radius: 4px;
	}
}
.about-us-page {
	.counter-section-wrap {
		@media (max-width: 767px) {
			padding-top: 40px;
		}
	}
}
