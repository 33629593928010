.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
	border-color: #8ca3f1 !important ;
	background-image: none !important;
}
select {
	webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* SVG background image */
	background-image: url('../../../assets/img/angle-arrow-down.svg');
	background-size: 12px;
	background-position: calc(100% - 1.3em) center;
	background-repeat: no-repeat;
}
select.form-control.is-invalid,
.was-validated select.form-control:invalid,
select.form-control.is-valid,
.was-validated select.form-control:valid {
	background-image: url('../../../assets/img/angle-arrow-down.svg') !important;
	background-size: 12px;
	background-position: calc(100% - 1.3em) center;
	background-repeat: no-repeat;
}
.branch-service-block input {
	background-image: url('../../../assets/img/angle-arrow-down.svg');
	background-size: 12px;
	background-position: calc(100% - 1.3em) center;
	background-repeat: no-repeat;
}
.form-control {
	height: 40px;
	border-radius: 4px;
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
	box-shadow: none;
	border: 1px solid rgba(0, 0, 0, 0.15);
	// background-color: #fafafa;
	// color: #2f3031;
	// border: 1px solid rgba(0, 0, 0, 0.24);
	color: #495057;
	background-clip: padding-box;
	background-color: #fff;
	border: 1px solid #e2e5ec;
	@media (max-width: 767px){
		font-size: 15px !important;
	}
}
// .input-group-text {
// 	background-color: #f9f9f9;
// border: 1px solid rgba(0, 0, 0, 0.24);
// }
.css-12jo7m5{
	@media (max-width: 767px){
		font-size: 13px !important;
	}
}
.form-group {
	@media (max-width: 767px){
		.css-yt9ioa-option,
		.css-1n7v3ny-option {
	    	font-size: 15px !important;
		}
	}
	.css-1pahdxg-control,
	.css-yk16xz-control{
		font-size: 15px !important;
		min-height: 40px !important;
	}
	.css-1hb7zxy-IndicatorsContainer{
		height: 38px !important;
	}
}
.custom-control {
	min-height: 1.3rem;
}
.form-control:focus {
	background-color: #fff;
	border-color: #8ca3f1 !important;
	//color: #2f3031;
	box-shadow: none !important;
	// border-color: #0071f5 !important;
}
.form-control::-webkit-input-placeholder {
	color: #9197a3;
	font-size: 15px;
}
.form-control::-moz-placeholder {
	color: #9197a3;
	font-size: 15px;
}
.form-control:-ms-input-placeholder {
	color: #9197a3;
	font-size: 15px;
}
.form-control:-moz-placeholder {
	color: #9197a3;
	font-size: 15px;
}
.form-group {
	// .css-1pahdxg-control {
	// 	box-shadow: none;
	// 	border-color: #8ca3f1;
	// 	&:hover {
	// 		border-color: #8ca3f1;
	// 	}
	// }
	.input-group-text {
		border: 1px solid #e2e5ec;
		background: #fff;
		color: #495057;
		&:hover,
		&:focus {
			border-color: #8ca3f1;
		}
	}
	.css-yk16xz-control {
		color: #6c7293;
		box-shadow: none;
		background-color: #fff;
		border: 1px solid #e2e5ec;
		&:hover {
			border-color: #e2e5ec;
		}
		&:focus {
			color: #495057;
			border-color: #8ca3f1;
		}
	}
	.css-xb97g8 {
		color: #172533;
		&:hover {
			background-color: #ffbdad;
			color: #de350b;
		}
	}
	.css-26l3qy-menu {
		background-color: #fff;
		color: #6c7293;
	}
	.css-yt9ioa-option {
		color: #6c7293 !important;
		&:hover {
			background: #f1f1f1;
			color: #000;
		}
	}
	.css-1n7v3ny-option {
		background-color: #e9ecef;
		color: #000;
		&:active {
			background-color: #ffffff;
		}
	}
	.css-1wa3eu0-placeholder {
		color: #495057;
	}
	.css-1gtu0rj-indicatorContainer {
		color: hsl(0, 0%, 80%);
		&:hover {
			color: hsl(0, 0%, 100%);
		}
	}
	.css-1rhbuit-multiValue {
		line-height: normal;
	}
	.css-1uccc91-singleValue {
		color: #495057;
	}
	.css-1pahdxg-control {
		color: #495057;
		box-shadow: none;
		background-color: #fff;
		border: 1px solid #e2e5ec;
		&:hover,
		&:focus {
			color: #495057;
			border-color: #8ca3f1;
		}
	}
	// .css-yk16xz-control {
	// 	border-color: #e2e5ec;
	// 	&:hover {
	// 		border-color: #e2e5ec;
	// 	}
	// }
}

// .signup-container {
// 	input {
// 		&:-internal-autofill-selected {
// 			background-color: rgba(232, 240, 254, 0) !important;
// 			background-image: none !important;
// 			color: #fbfbfb !important;
// 		}
// 	}

// 	input:-webkit-autofill {
// 		-webkit-animation-name: autofill;
// 		animation-name: autofill;
// 		-webkit-animation-fill-mode: both;
// 		animation-fill-mode: both;
// 	}
// }

@-webkit-keyframes autofill {
	to {
		color: #fbfbfb;
		background: transparent;
	}
}

@keyframes autofill {
	to {
		color: #fbfbfb;
		background: transparent;
	}
}

.custom-datepick-design {
	.react-datepicker-wrapper {
		display: block;
	}
	.form-control {
		background-image: url('../../../assets/img/calendar.svg');
		background-size: 15px;
		background-position: calc(100% - 0.2em) center;
		background-repeat: no-repeat;
		&.custom-time-picker {
			background-image: url('../../../assets/img/time.svg');
		}
	}
	.form-control[readonly] {
		background-color: #fff;
		opacity: 1;
	}
	// .flatpickr-mobile {
	//   background-image: url("../../../assets/img/calendar.svg");
	//   background-size: 15px;
	//   background-position: calc(100% - 1.3em) center;
	//   background-repeat: no-repeat;
	//   height: 40px;
	//   border-radius: 4px;
	//   font-size: 14px;
	//   font-weight: 400;
	//   line-height: 18px;
	//   box-shadow: none;
	//   color: #495057;
	//   background-color: #fff;
	//   background-clip: padding-box;
	//   border: 1px solid #e2e5ec;
	//   display: block;
	//   width: 100%;
	//   padding: 0.375rem 0.75rem;
	//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	//   &:focus {
	//     color: #495057;
	//     background-color: #fff;
	//     border-color: #8ca3f1 !important;
	//     box-shadow: none !important;
	//   }
	// }
	.flatpickr-mobile[readonly] {
		background-color: #ffffff;
		opacity: 1;
	}
}
.invalid-feedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 85%;
	color: #ff1f1f;
	line-height: 13px;
}
.form-vld {
	position: relative;
	margin-bottom: 16px;
	.invalid-feedback {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 85%;
		color: #ff1f1f;
		line-height: 13px;
	}
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
	border-color: #ff1f1f;
}
.our-custom-checkbox {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-flow: column;

	.custom-control-label::after {
		position: absolute;
		top: 4px;
		left: -23px;
		display: block;
		width: 16px;
		height: 16px;
		background: no-repeat;
		background-size: 12px;
	}

	.custom-checkbox
		.custom-control-input:checked
		~ .custom-control-label::after {
		background-image: url('../../../assets/img/checkmark.svg');
	}
	.custom-control-input {
		position: absolute;
		z-index: 1;
		opacity: 0;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	.custom-control-label {
		color: #6c7293;
		font-weight: 400;
		font-size: 14px;
	}
	.custom-control {
		padding-left: 26px;
		display: inline-block;
		min-height: 18px;
	}
	.active {
		.custom-control-label {
			color: #ed7600;
			font-size: 13px;
			font-weight: 700;
		}
	}
	.white-checkbox {
		.custom-control-label::before {
			border: solid 1px #ffffff;
		}
		.custom-control-label {
			color: #fff;
			font-weight: 400;
		}
	}
}
.custom-switch {
	.custom-control-label {
		padding-left: 20px;
		color: #6c7293;
		@media (max-width: 359px) {
			padding-left: 12px;
		}
	}
	.custom-control-label::before {
		background-color: #dfe6f5;
		border: none;
		width: 40px;
		height: 22px;
		border-radius: 20px;
		top: 0px;
	}
	.custom-control-label::after {
		background-color: #fff;
		top: 3px;
		left: -32px;
		width: 16px;
		height: 16px;
	}
	.custom-control-input:checked ~ .custom-control-label::after {
		transform: translateX(15px);
	}
	.custom-control-input:focus ~ .custom-control-label::before {
		box-shadow: none;
	}
}

.dropdown-menu {
	box-shadow: 0 1px 7px 1px rgba(1, 1, 1, 0.2);
	-webkit-box-shadow: 0 1px 7px 1px rgba(1, 1, 1, 0.2);
	border: 0px transparent;
	border-radius: 4px;
	color: #fafafa;
	background-color: #fff;
}
.dropdown-reset {
	padding: 15px 0;
	&:before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: -8px;
		right: 3px;
		box-sizing: border-box;
		border: 9px solid black;
		border-top-color: black;
		border-right-color: black;
		border-bottom-color: black;
		border-left-color: black;
		border-color: transparent transparent #fff #fff;
		transform-origin: 0 50%;
		transform: rotate(135deg) translateX(-50%);
		box-shadow: 0 0px 7px 1px rgba(1, 1, 1, 0.12);
	}
	&:after {
		content: '';
		display: inline-block;
		width: 0;
		height: 0;
		border: 19px solid #fff;
		border-color: transparent transparent #fff transparent;
		position: absolute;
		top: 1px;
		left: 50%;
		transform: translateX(-50%) rotate(-180deg);
		-webkit-transform: translateX(-50%) rotate(-180deg);
		-moz-transform: translateX(-50%) rotate(-180deg);
	}
}
.dropdown-manu {
	// .dropdown-menu.show {
	// 	border: none;
	// 	padding: 0px;
	// 	box-shadow: 0 1px 7px 1px rgba(1, 1, 1, 0.2);
	// 	-webkit-box-shadow: 0 1px 7px 1px rgba(1, 1, 1, 0.2);
	// 	border: 0px transparent;
	// 	border-radius: 4px;
	// 	.dropdown-item {
	// 		padding: 8px 24px;
	// 		color: #a7abc4;
	// 		font-size: 12px;
	// 		&:hover,
	// 		&:focus,
	// 		&:active {
	// 			background-color: #f7f8fa;
	// 			color: #00bece;
	// 		}
	// 		svg {
	// 			margin-right: 5px;
	// 		}
	// 	}
	// }
	button.dropdown-toggle,
	.btn-primary.dropdown-toggle {
		padding: 0px;
		background-color: transparent !important;
		box-shadow: none !important;

		&:hover,
		&:focus,
		&:active,
		&::after {
			background-color: transparent !important;
			box-shadow: none !important;
		}
	}
}
.file-input-upload {
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 175px;
	min-height: 175px;
	max-width: 100%;
	cursor: pointer;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: 100%;
	border: 2px dashed #e2e5ec;
	overflow: hidden;
	margin-bottom: 0px;
	.img-preview {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&.files-upload-height {
		min-height: 200px;
		max-height: 220px;
	}
	&.cover-upload-height {
		min-height: 127px;
		max-height: 127px;
	}

	.file-input-inner-upload {
		padding: 0 10px;
		img {
			width: 40px;
		}
		.custom-preview-img {
			width: auto;
			max-width: 100%;
			max-height: 100%;
		}
		h6 {
			font-size: 14px;
			margin: 15px 0 5px;
			color: #97979a;
		}
		p {
			font-size: 12px;
			color: #97979a;
			span {
				display: block;
			}
		}
	}
	input {
		cursor: pointer;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}

textarea.form-control {
	resize: none;
}
.form-group {
	margin-bottom: 20px;
	position: relative;
	label {
		color: #191919;
		letter-spacing: 0px;
		font-weight: 600;
	}
	.required-field {
		color: #fd397a;
	}
	.invalid-feedback {
		margin-top: 0px;
		font-size: 80%;
		color: #ff1f1f;
		position: absolute;
		line-height: 13px;
		// bottom: -16px;
	}
	.error-field {
		margin-top: 0px;
		font-size: 80%;
		color: #ff1f1f;
		position: absolute;
		line-height: 13px;
	}
	.studio-img-error {
		bottom: unset;
		top: 205px;
	}
}
.checkWrap {
	min-height: 20px;
	position: relative;
	// padding-left: 35px;
	padding-left: 18px;
	margin-bottom: 12px;
	cursor: pointer;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 16px;
		width: 16px;
		border-radius: 3px;
		background-color: #c7d1f5;
	}
	input:checked ~ .checkmark::before {
		display: block;
	}
	.checkmark::before {
		content: '';
		position: absolute;
		display: none;
		// left: 7px;
		// top: -4px;
		// width: 7px;
		// height: 16px;
		//border: solid #55555f;
		// border-width: 0 2px 2px 0;
		// transform: rotate(45deg);
		border: solid #99a6bf;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		top: 50%;
		left: 50%;
		margin-left: -2px;
		margin-top: -6px;
		width: 5px;
		height: 10px;
	}
}
.custom-checkbox [type='checkbox'] ~ .custom-control-label::before {
	color: #fff;
	border-color: transparent !important;
	top: 2px;
	left: -25px;
	display: block;
	height: 16px;
	width: 16px;
	border-radius: 3px;
	background-color: #e0e5f8 !important;
	box-shadow: none !important;
}
.custom-checkbox
	[type='checkbox'].custom-control-input:checked
	~ .custom-control-label::after {
	content: '';
	position: absolute;
	width: 7px;
	height: 16px;
	border: solid #99a6bf;
	border-width: 0 2px 2px 0;
	transform: rotate(46deg);
	top: -0.2rem;
	left: -1.2rem;
	background: none;
	margin-top: 6px;
	width: 5px;
	height: 10px;
}
.custom-radiobox [type='radio'] ~ .custom-control-label::after,
.custom-radiobox [type='radio'] ~ .custom-control-label::before {
	top: 2px;
}
.file-input.profile-wrap {
	background-size: 100% 100%;
	.file-input-inner {
		padding: 32px 0px;
	}
	width: 337px;
}
.date-time-show-schedule {
	-webkit-box-flex: 1;
	box-flex: 1;
	-webkit-flex-grow: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 1;
	flex-shrink: 1;
	min-width: 0;
	word-wrap: break-word;
	.day-hours-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		color: #6c7293;
		margin-bottom: 10px;
		.day {
			-webkit-box-flex: 0;
			box-flex: 0;
			-webkit-flex-grow: 0;
			flex-grow: 0;
			-webkit-flex-shrink: 0;
			flex-shrink: 0;
			overflow: hidden;
			padding-right: 10px;
			text-overflow: ellipsis;
			vertical-align: top;
			width: 100px;
		}
		.time {
			-webkit-box-flex: 1;
			box-flex: 1;
			-webkit-flex-grow: 1;
			flex-grow: 1;
			-webkit-flex-shrink: 1;
			flex-shrink: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			span {
				display: block;
			}
		}
	}
}
// .timings-modal-wrapper {
// 	.custom-scrollbar {
// 		max-height: 420px;
// 		overflow-y: auto;
// 		margin: 0 -1rem;
// 		padding: 0 1rem;
// 	}
// }
.timings-modal-wrapper {
	.modal-dialog {
		@media (min-width: 750px) and (max-width: 991px) {
			max-width: 730px;
		}
		@media (max-width: 749px) {
			max-width: 100%;
			width: auto;
			margin: 0.5rem;
		}
	}
}
.select-hours-wrapper {
	@media (max-width: 359px) {
		font-size: 13px;
	}
	.select-hours-item {
		margin: 15px 0;
		min-height: 40px;
		position: relative;

		.day {
			display: inline-block;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			vertical-align: top;
			width: 100px;
			color: #6c7293;
			@media (min-width: 649px) and (max-width: 749px) {
				width: 110px;
			}
			@media (max-width: 375px) {
				width: 92px;
			}
			@media (max-width: 359px) {
				width: 85px;
			}
		}
		.close-open-check {
			display: inline-block;
			margin: 0 15px 10px 5px;
			vertical-align: top;
			@media (min-width: 649px) and (max-width: 749px) {
				margin: 0 60px 10px 5px;
			}
		}
		.select-time-block {
			display: inline-block;
			margin-top: -10px;
			position: relative;
			vertical-align: top;

			.css-yk16xz-control {
				background-color: #fff;
				border: 1px solid #e2e5ec;
				.css-1uccc91-singleValue {
					color: #495057;
				}
			}
			.css-1pahdxg-control {
				background-color: #fff;
				border: 1px solid #e2e5ec;
				.css-1uccc91-singleValue {
					color: #495057;
				}
			}
			.css-26l3qy-menu {
				background-color: #fff;
				border: 1px solid #e2e5ec;
				color: #6c7293;
				.css-1n7v3ny-option {
					background-color: #f1f1f1;
					color: #6c7293;
				}
			}
			@media (max-width: 749px) {
				margin-top: 10px;
			}
			@media (max-width: 575px) {
				display: block;
			}
			.inner-block {
				.select-iteams {
					display: flex;
					align-items: center;
					.select-box {
						margin: 2px 0 25px;
						vertical-align: middle;
						width: 150px;
						display: inline-block;
						position: relative;
						@media (min-width: 750px) and (max-width: 991px) {
							width: 130px;
						}
						@media (min-width: 649px) and (max-width: 749px) {
							width: 175px;
						}
						@media (max-width: 575px) {
							width: 125px;
						}
						.errror-field {
							width: 100%;
							margin-top: 1px;
							font-size: 80%;
							color: rgb(255, 31, 31);
							position: absolute;
							margin-bottom: 0px;
							line-height: 12px;
						}
					}
					.des-col {
						color: #70757a;
						display: inline-block;
						margin: -18px 10px 0;
						vertical-align: middle;
						width: 1em;
					}
					.cancle-col {
						width: 48px;
						height: 38px;
						position: relative;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						vertical-align: middle;
						// margin-top: -8px;
						margin-top: 0px;
						margin-bottom: 25px;
						.btn-link {
							padding: 8px;
							vertical-align: middle;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							.svg-inline--fa {
								width: 20px;
								height: 20px;
								vertical-align: initial;
								color: #ccc;
								fill: #ccc;
								&:hover {
									color: #ddd;
									fill: #ddd;
								}
							}
						}
					}
				}
			}
		}
		.add-hours-block {
			display: inline-block;
			margin-top: -10px;
			position: relative;
			vertical-align: middle;
			@media (max-width: 749px) {
				margin-top: 10px;
			}
			@media (max-width: 575px) {
				position: absolute;
				top: -17px;
				right: 4px;
			}
			.inner-block {
				padding-left: 25px;
				@media (min-width: 750px) and (max-width: 991px) {
					padding-left: 8px;
				}
				@media (min-width: 576px) and (max-width: 649px) {
					padding-left: 10px;
				}
				.btn-link {
					background: #114699;
					color: #cccccc;
					font-size: 14px;
					font-weight: 500;
					transition: 0.3s ease;
					@media (max-width: 575px) {
						font-size: 11px;
					}
					@media (max-width: 359px) {
						font-size: 13px;
					}
					&:hover {
						color: #fff;
						text-decoration: none;
					}
				}
			}
		}
	}
}

.setting-card-wrap {
	.input-note {
		font-size: 13px;
		color: #74788d;
		// font-style: italic;
		margin-top: 10px;
	}
}
.imagepreview {
	background-image: url(blob:http://localhost:3000/b36f22a6-1479-43e2-844c-de89f2974cfd);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.input-group-append {
	margin-left: 0px;
	.btn-secondary {
		padding: 5px 15px;
		border: 1px solid #e2e5ec;
		background: #fff;
		color: #a3a6bd;
		box-shadow: none;
		&:hover {
			background-color: #f1f1f1;
			color: #a3a6bd;
			box-shadow: none !important;
		}
	}
}

.add-service-modal {
	.file-input-upload {
		max-height: 170px;
		min-height: 170px;
	}
}
.add-staff-modal {
	.file-input-upload {
		max-height: 317px;
		min-height: 317px;
	}
	.modal-inner-title {
		color: #fff;
		font-size: 17px;
		font-weight: 500;
	}
}
.staff-form {
	.file-input-upload {
		max-height: 220px;
		min-height: 220px;
	}
}
.subscription-form {
	.file-input-upload {
		max-height: 261px;
		min-height: 261px;
	}
}
.service-form {
	.file-input-upload {
		max-height: 170px;
		min-height: 170px;
	}
}

.file-input-upload.minheight265.support-forminput {
	max-height: 280px;
	min-height: 280px;
}

.branch-modal-card {
	.card {
		box-shadow: none;
		background-color: transparent;
		.card-header {
			padding: 0px;
			border: none;
			margin-bottom: 1rem;
			display: none;
			.card-title {
				color: #606061;
				font-size: 17px;
				font-family: 'gilroysemibold';
			}
		}
		.card-body {
			padding: 0px;
		}
	}
}
.form-control:disabled {
	background-color: #ffffff;
}
.select-box-wrap {
	height: 35px;
	border: 1px solid #e2e5ec;
	padding-left: 20px;
	padding-right: 35px;
	select {
		background-position: calc(100% - 0.7em) center !important;
	}
}

// ====================For common form start=================
.custom-labe1 {
	font-size: 16px;
	color: #000 !important;
	margin: 0;
	.required-field {
		color: #ff5454 !important;
		font-size: 18px;
	}
}
.custom-field1,
.custom-field1.form-control.is-invalid {
	border: 1px solid #b5b8cc !important;
	border-radius: 0 !important;
	color: #444444 !important;
	&::placeholder {
		color: #444444 !important;
	}
}
select.custom-field {
	padding-right: 38px;
	text-overflow: ellipsis;
}
.custom-field[readonly] {
	background: #efefef;
	border: 0 !important;
}
.error-sms {
	position: static !important;
	font-size: 13px !important;
	margin: 6px 0 0 !important;
	color: #ff5454 !important;
}
.change-booked {
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
}
.checkbax-wrapper {
	.custom-checkbox {
		.custom-control-label {
			color: #444444;
			line-height: 1.6;
			padding-left: 5px;
			&:before {
				height: 18px;
				width: 18px;
				border-radius: 0;
				background-color: transparent !important;
				border-color: #b5b8cc !important;
			}
		}
	}
	.custom-control-input:checked ~ .custom-control-label::before {
		color: #fff;
		border-color: #0034ec !important;
		background-color: #0034ec !important;
	}
	.custom-checkbox
		[type='checkbox'].custom-control-input:checked
		~ .custom-control-label::after {
		border: solid #ffffff;
		border-width: 0 2px 2px 0;
		top: -2px;
		left: -18px;
	}
}
.half-profile-wrapper {
	max-width: 340px;
	height: 214px;
	max-height: 214px;
	align-items: flex-end;
	padding: 20px 10px;
	@media (max-width: 991px) {
		max-width: 100%;
		height: 214px;
		max-height: 214px;
	}
	.file-input-inner-upload {
		h6 {
			font-size: 15px;
			margin: 25px 0 30px;
		}
		p {
			font-size: 15px;
			font-family: 'gilroyregular';
		}
	}
}
.datepicker-icon.custom-datepick-design {
	position: relative;
	.form-control {
		background: transparent;
		padding-right: 0;
	}
	.arrow-date {
		position: absolute;
		top: 0;
		height: 100%;
		width: 42px;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f4f5f8;
		border: solid 1px #e2e5ec;
	}
}

.select-h-150 {
	.custom-inner-reactselect-starttime__menu-list {
		max-height: 150px !important;
	}
}
.select-h-70 {
	.custom-inner-reactselect-duration__menu-list {
		max-height: 70px;
		position: absolute;
		width: 100%;
		background: #fff;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
	}
}

// ====================For common form end=================
