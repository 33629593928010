// VARIABLES
@import '../../variables/color.scss';
// @import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Overpass:300,300i,400,400i,600,600i,700,700i&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900&display=swap");

.theme-color {
	color: $heading-icon-color;
}
:focus {
	outline: none !important;
	box-shadow: none !important;
}
body {
	font-size: 15px;
}

.btn:focus {
	box-shadow: none !important;
}
a {
	cursor: pointer;
}
a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
}
button[disabled],
html input[disabled] {
	cursor: not-allowed;
}
.mandatory {
	color: red;
}
.link-text {
	cursor: pointer;
	color: #101010;
	transition: 0.3s ease;
	&:hover {
		color: #114699;
	}
}
.fw500 {
	font-weight: 500;
}
.file-input-upload.minheight265 {
	max-height: 305px;
	min-height: 305px;
}
.center-block {
	float: none;
	margin: 0 auto;
}
.no-list-style {
	list-style: none;
}
.first-caps::first-letter {
	text-transform: capitalize;
}
.card {
	border: none;
	border-radius: 4px;
	height: 100%;
	background-color: #ffffff;
	border-radius: 6px;
	box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
	.card-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 30px 15px;
		border: none;
		border-radius: 0px;
		background: #ffffff;
		border-bottom: 1px solid #efefef;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		&:first-child {
			border-radius: 0px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
	}
	.card-title {
		margin: 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: 'gilroysemibold';
		color: #191919;
		font-size: 20px;
		@media (max-width: 991px) {
			font-size: 18px;
		}
	}
	.header-actions {
		display: flex;
		align-items: center;
		.btn-link {
			width: 32px;
			height: 32px;
			border-radius: 4px;
			position: relative;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 5px;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			svg {
				path {
					color: #b4b8ce;
				}
			}
			&:hover {
				background-color: #e1e3ec;
				svg {
					path {
						color: #5d78ff;
					}
				}
			}
		}
		// .svg-inline--fa {
		// 	color: $heading-icon-color;
		// 	margin: 0px 10px;
		// 	font-size: 14px;
		// 	@media (max-width: 575px) {
		// 		margin: 0px 0px;
		// 	}
		// }
		.dropdown-toggle::after {
			display: none;
		}
		.dropdown {
			.svg-inline--fa {
				@media (max-width: 575px) {
					margin-right: 8px;
				}
			}
		}
		.search-dropdown {
			.dropdown-reset {
				margin-top: 15px;
				width: 220px;
				padding: 0;
				left: 10px !important;
				&:before {
					right: 8px;
				}
				&:after {
					left: auto;
					right: -4px;
					background: #fff;
					border: 15px solid #fff;
				}
			}
			.search-input-wrap {
				position: relative;
				z-index: 1;
				input {
					padding-left: 30px;
					border-color: #fff;
				}
				img {
					position: absolute;
					left: 6px;
					top: 11px;
				}
			}
			&.show {
				.btn-link {
					background-color: #f4f5f9;
				}
			}
		}
	}
	.card-body {
		padding: 15px;
		&.table-card-body {
			padding: 0px;
		}
	}
}

// ====== Staff Details Css ========
.staff-detail-profile {
	.profile-img {
		position: relative;
		// width: 120px;
		// height: 120px;
		padding-right: 15px;
		img {
			width: 90px;
			height: 90px;
			border-radius: 8px;
		}
	}
	h5 {
		font-size: 20px;
		text-transform: capitalize;
		margin: 0px 0;
		color: #48465b;
		font-weight: 500;
	}
	.staff_subtitle {
		font-size: 13px;
		display: block;
		padding: 4px 0 0 0;
		font-weight: 500;
		color: #74788d;
	}
	.info-content {
		.tile-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
			.left-tile {
				min-width: 85px;
				text-align: left;
				text-transform: capitalize;
				font-weight: 500;
				color: #48465b;
				font-size: 13px;
			}
			.right-tile {
				text-align: right;
				flex: 1;
				color: #74788d;
				font-weight: 400;
				word-wrap: break-word;
				word-break: break-word;
				font-size: 13px;
			}
		}
	}
}
.text-label {
	letter-spacing: 0px;
	font-weight: 600;
	color: #6c7293;
}
.text-value {
	font-weight: 400;
	color: #6c7293;
}
.badge {
	font-size: 11px;
	line-height: 1.5;
	font-weight: 500;
	border-radius: 4px;
	padding: 7px 15px;
	&.badge-small {
		padding: 3px 10px;
		min-width: 65px;
	}
	&.badge-success {
		background-color: rgba(29, 201, 183, 0.18);
		color: #1dc9b7;
	}
	&.badge-danger {
		background-color: rgba(253, 57, 122, 0.18);
		color: #fd397a;
	}
	&.badge-warning {
		background-color: rgba(255, 184, 34, 0.18);
		color: #ffb822;
	}
	&.badge-info,
	&.badge-inprogress {
		background-color: rgba(85, 120, 235, 0.18);
		color: #5578eb;
	}
}

.dark-theme-text {
	color: #74788d;
}
.faq-text {
	p {
		border-bottom: 1px solid #eeeef4;
		padding: 13px 13px 13px 0px;
		margin-bottom: 0px;
		font-size: 15px;
		font-weight: 500;
		color: #767688;
		&:last-child {
			border: none;
		}
	}
}
.image-section {
	position: relative;
	height: 200px;
	margin-bottom: 20px;
	.info-image {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}
}
.branch-timing {
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}

.list-info-wrap {
	list-style: none;
	padding-left: 0px;
	max-height: 250px;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0px;
	.no-result-block {
		min-height: 245px;
	}
}
.contact-text {
	color: #232323;
	font-weight: 400;
	line-height: 22px;
	a {
		&:hover {
			color: #3856ff;
		}
	}
}
.icon-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	padding: 0px;
	width: 35px;
	height: 35px;
	font-size: 15px;
	border-radius: 50%;
	flex: 0 0 35px;
	max-width: 35px;
	&.icon-1 {
		background-color: #5d78ff;
		border-color: #5d78ff;
		color: #fff;
	}
	&.icon-2 {
		background-color: #1dc9b7;
		border-color: #1dc9b7;
		color: #fff;
	}
	&.icon-3 {
		background-color: #fd27eb;
		border-color: #fd27eb;
		color: #fff;
	}
	&.icon-4 {
		background-color: #ffb822;
		border-color: #ffb822;
		color: #fff;
	}
}
// ===================== User Card Css ===============
.hb-user-card {
	display: flex;
	align-items: center;
	padding: 0;
	margin: 0;
	margin-bottom: 10px;
	position: relative;
	.hb-user-card-pic {
		margin-right: 0.5rem;
		position: relative;
		height: 40px;
		width: 40px;
		overflow: hidden;
		flex: 0 0 40px;
		max-width: 40px;
		img {
			border-radius: 100%;
			width: 40px;
			height: 40px;
		}
	}
	.hb-user-card-details {
		line-height: 0;
		.hb-user-card-name {
			display: block;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			transition: color 0.3s ease;
			color: #6c7293;
		}
		.hb-user-card-desc {
			display: inline-block;
			font-size: 12px;
			line-height: 16px;
			font-weight: 400;
			color: #6c7293;
		}
	}
}
// ====================== Modal Css ===========
.modal-content {
	box-shadow: 0 1px 7px 1px rgba(1, 1, 1, 0.2);
	-webkit-box-shadow: 0 1px 7px 1px rgba(1, 1, 1, 0.2);
	border: 0px;
	background: #fff;
	h4 {
		color: #000;
	}
	.modal-header {
		background: #fff;
		border-bottom: 1px solid #dfe0e0;
		padding: 15px;
		color: #6c7293;
		align-items: center;
		@media (max-width: 767px) {
			padding: 10px 15px;
		}
		.modal-close,
		button {
			padding: 0 0 0 8px;
			margin: 0 0;
			line-height: 12px;
		}
		.modal-title {
			margin-bottom: 0;
			font-weight: 500;
			line-height: 1.2;
			font-size: 1.25rem;
			.modal-body {
				padding: 15px;
			}
		}
	}
	.modal-close {
		opacity: 1 !important;
		img {
			width: 14px;
		}
		.main-img {
			opacity: 1;
			display: inline-block;
		}
		.hover-img {
			opacity: 0;
			display: none;
		}
		&:hover {
			opacity: 1 !important;
			.main-img {
				opacity: 0;
				display: none;
			}
			.hover-img {
				opacity: 1;
				display: inline-block;
			}
		}
	}
	.modal-inner-title {
		color: #fff;
		font-size: 17px;
		font-weight: 500;
	}
}
.add-branch-modal {
	.modal-dialog {
		@media (min-width: 576px) {
			max-width: 500px;
		}
		@media (min-width: 768px) {
			max-width: 700px;
		}
		@media (min-width: 992px) {
			max-width: 970px;
		}
	}
}
.add-service-modal,
.add-staff-modal {
	.modal-dialog {
		@media (max-width: 575px) {
			margin: 22px;
		}
		@media (min-width: 576px) {
			max-width: 470px;
		}
		@media (min-width: 768px) {
			max-width: 670px;
		}
		@media (min-width: 992px) {
			max-width: 800px;
		}
		.modal-content {
			box-shadow: 0 1px 8px 3px #4e545d;
			-webkit-box-shadow: 0 1px 8px 3px #4e545d;
			border: 0px;
			background: #ffffff;
		}
	}
}
.flatpickr-calendar {
	font-size: 14px !important;
	&.open {
		z-index: 9 !important;
	}
}
.flatpickr-current-month {
	font-size: 14px !important;
	font-family: 'gilroysemibold';
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
	top: 5px !important;
	padding: 0px 10px !important;
}
span.flatpickr-weekday {
	background: #d8d8d8 !important;
	color: rgba(255, 255, 255, 0.81) !important;
	font-family: 'gilroysemibold';
	font-size: 14px !important;
}
.flatpickr-weekdays {
	background: #d8d8d8 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
	background: #d8d8d8 !important;
}
.flatpickr-months .flatpickr-month {
	background: #d8d8d8 !important;
}
.flatpickr-calendar.arrowTop:after {
	border-bottom-color: #d8d8d8 !important;
}
.flatpickr-calendar.arrowBottom:after {
	border-top-color: #d8d8d8 !important;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
	fill: #c0c4d4 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
	background: rgba(0, 0, 0, 0.05) !important;
}

.flatpickr-day.today {
	border-color: #d8d8d8 !important;
	background: #d8d8d8 !important;
	color: #fff !important;
	font-family: 'gilroysemibold';
}
.flatpickr-day.flatpickr-disabled {
	color: rgba(72, 72, 72, 0.32);
}
.flatpickr-day.selected {
	border-color: #114699;
	background: #114699;
	color: #fff !important;
	&:hover {
		border-color: #114699;
		background: #114699;
		color: #fff !important;
	}
}
.flatpickr-current-month
	.flatpickr-monthDropdown-months
	.flatpickr-monthDropdown-month {
	background-color: #506ee4 !important;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
	background: #ffffff !important;
}
//  service list css
// .text-ellipsis {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
.cursor-pointer {
	cursor: pointer;
}
.price-text {
	font-size: 15px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	color: #fd397a;
}
.btn-edit {
	background: #cfd4e8;
	border-color: #cfd4e8;
	color: #4f5365;
	font-weight: 500;
	border-radius: 5px;
	padding: 4px 14px;
	font-size: 13px;
	line-height: 20px;
	@media (max-width: 567px) {
		max-width: 80px;
		margin: 10px 0 !important;
	}
	&:hover {
		color: #fff;
		background: #5d78ff;
	}
	svg {
		font-size: 20px;
	}
}
.subservice-list {
	padding-left: 0px;
	max-height: 455px;
	overflow-y: auto;
	overflow-x: hidden;
	li {
		color: #74788d;
		font-size: 14px;
		margin-bottom: 4px;
		svg {
			font-size: 20px;
			vertical-align: middle;
		}
		span {
			vertical-align: middle;
		}
	}
}
.event-timing {
	max-height: 515px;
	overflow-y: auto;
	overflow-x: hidden;
}
.class-timing {
	max-height: 485px;
	overflow-y: auto;
	overflow-x: hidden;
}
.tooltip-inner {
	font-size: 11px;
	font-family: Poppins, sans-serif;
}
.faq-section {
	max-height: 404px;
	overflow-y: auto;
	overflow-x: hidden;
	.faq-card {
		// background: #fff;
		border-bottom: 1px solid #cacbce;
		.faq-header {
			font-weight: 600;
			color: #232323;
			padding: 12px 0px;
			padding-right: 15px;
			border-bottom: none;
			position: relative;
			cursor: pointer;
			.faq-icon {
				position: absolute;
				right: 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				transition: all 0.3s ease;
			}
			&.active {
				// color: #ffffff;
				.faq-icon {
					transform: rotate(180deg);
				}
			}
		}
		.faq-body {
			padding: 0px 0px 12px;
			color: #232323 !important;
			font-size: 13px;
			.texteditor-text span {
				color: #232323 !important;
				background: transparent !important;
			}
		}
	}
}
.card {
	.faq-main-card-header {
		padding: 11px 15px;
	}
	.order-customer-card-header {
		padding: 10px 15px;
		@media (max-width: 500px) {
			display: flex;
			flex-flow: wrap;
		}
		.search-customer-box {
			flex: 0 0 200px;
			max-width: 200px;
			@media (max-width: 991px) {
				flex: 0 0 185px;
				max-width: 185px;
			}
			@media (max-width: 500px) {
				flex: 0 0 80%;
				max-width: 80%;
				margin: 10px 0;
			}
		}
		.btn-reset {
			height: 36px;
			width: 36px;
			text-align: center;
			padding: 0px;
			margin-left: 10px;
			@media (max-width: 500px) {
				margin-left: 0;
				margin-top: 10px;
			}
		}
	}
	.order-service-card-header {
		padding: 19px 15px;
	}
}
.btn-view {
	background-color: rgb(60, 77, 94);
	color: #e2e2e2;
	// padding: 4px 13px;
	padding: 6px 10px 6px 10px;
	border-radius: 4px;
	font-size: 15px;
	cursor: pointer;
	transition: all 0.3s ease;
	&:hover {
		background-color: #114699;
		color: #fff;
	}
}

.single-faq-section {
	padding: 30px 30px;
	position: relative;
	@media (max-width: 991px) {
		padding: 30px 10px;
	}
	.faq-card {
		margin-bottom: 16px;
		border-radius: 4px;
		background-color: #f1f1f1;
		border-color: transparent;
		box-shadow: 0 2px 7px 0 #dddede;
		border: 1px solid transparent;
		.faq-header {
			color: #232323;
			background-color: #f1f1f1;
			border-color: transparent;
			padding: 12px 45px 12px 15px;
			font-size: 15px;
			line-height: 20px;
			font-weight: 600;
			border-bottom: none;
			position: relative;
			cursor: pointer;
			letter-spacing: 0px;
			border-radius: 4px;
			.faq-icon {
				position: absolute;
				right: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				transition: all 0.3s ease;
				color: #232323;
			}
			&.active {
				color: #232323;
				.faq-icon {
					transform: rotate(180deg);
					color: #232323;
				}
			}
		}
		.faq-body {
			padding: 15px 20px;
			color: #232323 !important;
			background-color: #e0e0e0;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 0px;
			.texteditor-text span {
				color: #232323 !important;
				background: transparent !important;
			}
		}
	}
}

.file-list-item {
	background: #fff;
	box-shadow: 0 0 10px 0 rgba(0, 11, 40, 0.08);
	padding: 15px 10px;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.file-left-block {
		width: calc(100% - 80px);
		.file-name {
			font-size: 12px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
	.file-right-block {
		width: 80px;
		text-align: right;
		.file-size {
			margin-right: 10px;
			color: #05f;
			font-size: 12px;
			display: inline-block;
		}
		.file-remove {
			cursor: pointer;
			display: inline-block;
			img {
				width: 11px;
			}
			.main-img {
				opacity: 1;
				display: inline-block;
			}
			.hover-img {
				opacity: 0;
				display: none;
			}
			&:hover {
				.main-img {
					opacity: 0;
					display: none;
				}
				.hover-img {
					opacity: 1;
					display: inline-block;
				}
			}
		}
	}
}
.blur-graph {
	-webkit-filter: blur(3px);
	filter: blur(3px);
}
.coming-soon-graph {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0px;
	top: 0px;
	background: rgba(232, 232, 232, 0.47);
	width: 100%;
	height: 100%;
	font-size: 22px;
	font-weight: 500;
	color: #000;
}

// dashboard section

.sales-section {
	border-bottom: 1px solid #efefef;
	&:last-child {
		border-bottom: none;
	}
	.sales-count {
		font-weight: 500;
		font-size: 20px;

		&.info-text {
			color: #5867dd;
		}
		&.danger-text {
			color: #fd397a;
		}
		&.success-text {
			color: #1dc9b7;
		}
	}
}

.sales-price {
	border-bottom: 1px solid #efefef;
	h1 {
		font-size: 50px;
		color: #34bea4;
	}
}
.sales-counter {
	h3 {
		color: #202020;
	}
	p {
		font-size: 15px;
		color: #74788d;
	}
	span {
		color: #333;
	}
}
.staff-item {
	border-bottom: 1px solid rgba(116, 120, 141, 0.56);
	&:last-child {
		border-bottom: none;
	}
	.staff-img {
		img {
			width: 35px;
			border-radius: 50%;
		}
	}
	.staff-info {
		.staff-username {
			font-weight: 500;
			font-size: 15px;
			color: #f4f4f4;
			line-height: 24px;
		}
		.staff-text {
			font-size: 13px;
			color: #74788d;
		}
	}
	.staff-price {
		font-size: 16px;
		font-weight: 500;
		color: #572cbf;
	}
}
.seller-item {
	border-bottom: 1px solid rgba(116, 120, 141, 0.56);
	padding: 20px 0px;
	&:last-child {
		border-bottom: none;
	}
	.seller-info {
		.seller-username {
			font-weight: 500;
			font-size: 15px;
			color: #f4f4f4;
			line-height: 24px;
		}
		.seller-text {
			font-size: 13px;
			color: #a4a4a4;
		}
	}
	.seller-sales,
	.seller-income {
		font-size: 18px;
		font-weight: 500;
		color: #cdcdcd;
		padding: 0px 10px;
		span {
			font-size: 13px;
			font-weight: 400;
			color: #818181;
		}
	}
}

.custom-nav-tabs {
	margin-bottom: 20px;
	.nav-item {
		margin-right: 1px;
		.nav-link {
			padding: 10px 20px;
			padding-left: 45px;
			display: block;
			position: relative;
			width: 100%;
			background: #fff;
			color: #191919;
			margin: 0;
			border-radius: 0px;
			cursor: pointer;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
			font-family: 'gilroysemibold';
			font-size: 13px;
			@media (max-width: 723px) {
				margin-bottom: 10px;
			}
			.nav-icon {
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
				display: inline-block;
				width: 18px;
				height: 18px;
				img {
					position: absolute;
					width: 100%;
					height: 100%;
				}
				.main-img {
					opacity: 1;
					z-index: 1;
				}
				.hover-img {
					opacity: 0;
					z-index: -1;
				}
			}
			&.active {
				background: #114699;
				color: #fff;
				.nav-icon {
					.main-img {
						opacity: 0;
						z-index: -1;
					}
					.hover-img {
						opacity: 1;
						z-index: 1;
					}
				}
			}
		}
		&:hover {
			.nav-link {
				background: #114699;
				color: #fff;
				.nav-icon {
					.main-img {
						opacity: 0;
						z-index: -1;
					}
					.hover-img {
						opacity: 1;
						z-index: 1;
					}
				}
			}
		}
	}
	.wellness-tab {
		@media (max-width: 767px) {
			a {
				min-width: 161px;
			}
		}
	}
	&.nav {
		@media (max-width: 767px) {
			justify-content: center;
		}
	}
	.service-tabs {
		@media (max-width: 767px) {
			a {
				min-width: 180px;
			}
		}
	}
}

// ================== Calendar EVENT Details CSS =====================
.event-details-modal {
	.text-label {
		letter-spacing: 0px;
		font-weight: 700;
		color: #fff;
		margin-bottom: 0;
		margin-right: 10px;
	}
	.date {
		position: relative;
		display: inline-block;
	}

	h4 {
		font-size: 20px;
		margin: 0;
		font-weight: 700;
		margin-bottom: 20px;
	}

	.card {
		padding: 15px 15px 0;
		border: 1px solid #ccc;
		height: 100%;
	}
	.custom-scrollbar {
		max-height: 550px;
		overflow-y: auto;
		margin: 0 -1rem;
		padding: 0 1rem;
	}

	.custom-scrollbar::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}
	.custom-scrollbar::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
	}
	.custom-scrollbar::-webkit-scrollbar-thumb {
		background-color: #c1c1c1;
	}
	.delete-btn {
		border-color: #9298a5;
		color: #fff;
		background-color: #9298a5;
		background: linear-gradient(118deg, #8e8f92 4%, #9298a5 86%);
	}
	.loader-full-page {
		height: 70px;
		.loader-block {
			.loader {
				width: 40px;
				height: 40px;
			}
		}
	}
	.slot-modal-action-wrap {
		span {
			cursor: pointer;
		}
	}
}

.custom-scrollbar {
	// overflow: auto;
	// height: 310px;
	.appoinment-card {
		thead {
			background: #f3f3f3;
			box-shadow: 0px 3px 6px -2px #2a2b2f63;
			tr {
				background: #f3f3f3;
				th {
					position: sticky;
					background: #f3f3f3;
					top: 0;
					padding: 10px 15px;
					z-index: 9;
					color: #232323;
					font-weight: 700;
				}
			}
		}
		.apoinment-card-border {
			padding: 10px 0;
			margin: 15px 0;
			border-bottom: 1px solid #c7c7c7;
			.price-info {
				span {
					color: #232323;
				}
			}
			.date-col {
				width: 90px;
			}
			td {
				padding: 10px 15px;
			}
		}
		.date {
			font-weight: 700;
			font-size: 14px;
			color: #232323;
			line-height: 20px;
			span {
				color: #67768c;
				font-size: 12px;
				display: block;
				text-align: center;
			}
		}
		.customer-name {
			span {
				font-weight: 500;
				font-size: 14px;
				color: #232323;
			}
		}
		.time-date-wrap {
			.time-date {
				span {
					font-size: 13px;
					color: #74788d;
				}
				.appointment-label {
					width: 50px;
					position: relative;
					display: inline-block;
					font-weight: 600;
					&:after {
						position: absolute;
						top: 0;
						left: auto;
						right: 4px;
						content: ':';
					}
				}
			}
			.event-name {
				font-size: 14px;
				font-weight: 600;
				margin: 3px 0;
				color: #232323;
			}
		}
	}
}
.custom-card-header {
	padding-left: 0;
	padding-right: 0;
	.card-title {
		width: 100%;
		font-size: 14px;
		font-family: 'gilroysemibold';
	}
	.appointment-header {
		margin-top: 15px;
		.date-label {
			flex: 0 0 80px;
		}

		.table-type {
			width: 80px;
		}
		.table-amount {
			width: 63px;
		}
		.date-details {
			flex: 0 0 246px;
		}
		.date-type {
			flex: 0 0 80px;
		}
		.date-amount {
			flex: 1 1 63px;
		}
	}
	.latest-appointment-header {
		margin-top: 15px;
		.date-label {
			flex: 0 0 70px;
		}
		.date-details {
			flex: 1 1 290px;
		}
		.date-type {
			flex: 1 1 140px;
		}
		.date-amount {
			flex: 1 1 63px;
		}
	}
	.custom-title {
		color: #191919;
		font-size: 22px;
		line-height: 26px;
		@media (max-width: 991px) {
			font-size: 18px;
		}
	}
}
.appoinment-table-head {
	.table-date-col {
		width: 80px;
	}
	.table-detail {
		width: 247px;
	}
	.table-type {
		width: 80px;
	}
	.table-amount {
		width: 63px;
	}
}

.responsive-navbar {
	.navbar-brand {
		@media (max-width: 396px) {
			margin-right: 5px;
		}
	}
	.responsive-navbar-searchbar {
		@media (max-width: 396px) {
			width: 70%;
			padding-right: 10px;
		}
	}
}
.mob-card {
	.card-header {
		@media (max-width: 546px) {
			display: block;
		}
	}
}

.booking-detail-wrap {
	.row {
		margin-bottom: 0.8rem;
		@media (max-width: 546px) {
			margin-bottom: 1rem;
		}
	}
}

.account-active {
	color: #27a844;
	font-size: 14px;
	font-weight: 600;
}
.account-incative {
	color: orange;
}
.account-not-added {
	color: red;
}
// =========Common Style Start=============
/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.box-wrapper {
	.card {
		.card-header {
			&:first-child {
				border-radius: 6px;
			}
		}
	}
	.box-body {
		padding: 22px 30px;
	}
}
.main-title {
	font-size: 18px;
	font-weight: 600;
	color: #191919;
	.link-text {
		color: #191919;
		margin-left: 0;
		margin-right: 16px;
	}
}
.subTitle {
	font-size: 16px;
	color: #191919;
	margin: 0;
	font-weight: 600;
}
.update-text {
	color: #114699;
	font-size: 16px;
	cursor: pointer;
	background: transparent;
	border: 0;
	padding: 0;
}
.common-btn {
	display: inline-block;
	min-width: 160px;
	background: #000;
	font-size: 14px;
	color: #fff;
	text-align: center;
	padding: 10px 15px;
	font-weight: 600;
	box-shadow: none;
	border: 1px solid #000000;
	border-radius: 0;
	line-height: initial;
	&:focus,
	&:hover {
		border-color: #114699;
		background: #114699;
		color: #fff;
	}
}
.common-btn.outline-btn {
	background: transparent;
	color: #000;
	&:focus,
	&:hover {
		background: #000;
		color: #fff;
	}
}
.common-btn.small-btn {
	padding: 6px 10px;
	min-width: 82px;
}
.table-header-actions {
	.add-new-btn {
		border: solid 1px #101010 !important;
	}
}
.table-header-actions {
	.add-new-btn.outline-new-btn {
		background: transparent !important;
		color: #101010 !important;
		padding: 9px 15px !important;
		&:hover {
			background: #101010 !important;
			color: #fff !important;
		}
	}
}
.link-wrapper {
	font-size: 16px;
	color: #000;
	a {
		color: #114699;
	}
}
.noti-drop-icon {
	display: inline-block;
	position: relative;
	img {
		width: 25px;
	}
	span {
		display: inline-block;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background: #ff5454;
		position: absolute;
		bottom: 7px;
		text-indent: -9999px;
		right: 2px;
	}
	// span{
	//     display: flex;
	//     width: 9px;
	//     height: 10px;
	//     border-radius: 50%;
	//     background: #FF5454;
	//     position: absolute;
	//     bottom: 4px;
	//     right: 1px;
	//     font-size: 8px;
	//     color: #fff;
	//     text-align: center;
	//     padding: 1px;
	//     justify-content: center;
	//     align-items: center;
	// }
}
.navbar-menu-wrapper {
	.dropdown-menu {
		box-shadow: 10px 20px 100px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		max-width: 390px;
		padding-bottom: 0;
		@media (max-width: 767px) {
			max-width: 272px;
		}
		@media (max-width: 576px) {
			max-width: 250px;
		}
		.noti-list-wrapper {
			h3 {
				color: #191919;
				margin: 0;
				font-size: 16px;
				font-weight: 600;
				text-align: center;
			}
			ul {
				padding: 0;
				max-height: 350px;
				overflow: auto;
				li {
					list-style: none;
					a {
						display: flex;
						padding: 8px 15px;
						white-space: normal;
						font-size: 15px;
						img {
							width: 40px;
							height: 40px;
							border-radius: 50%;
							margin-right: 15px;
							object-fit: cover;
							object-position: center top;
						}
						p {
							margin: 0;
							color: #191919;
							line-height: 1.3;
							span {
								font-weight: 600;
							}
						}
						.notification-customer-name {
							width: 100%;
						}
						.notification-time {
							font-size: 13px;
							font-weight: 600;
							margin-top: 2px;
						}
					}
					&:hover {
						a {
							background: #e7e7e7;
						}
					}
					&:last-child {
						a {
							border-bottom-right-radius: 6px;
							border-bottom-left-radius: 6px;
						}
					}
				}
				.active {
					a {
						background: #e7e7e7;
					}
				}
			}
		}
	}
}
.view-all-wrapper {
	button {
		padding: 0;
		background: transparent;
		border: 0;
		&:hover {
			color: #114699;
		}
	}
}
// =========Common Style End===============
// =========Margin, Padding & width Start=========
.mb-25 {
	margin-bottom: 25px !important;
}
.h-127 {
	height: 127px !important;
	@media (max-width: 991px) {
		height: 86px !important;
	}
}
.min-h-460 {
	min-height: 460px;
}
.min-h-370 {
	min-height: 370px;
	@media (max-width: 767px) {
		min-height: auto;
	}
}
// =========Margin, Padding & width End===========
// =========For week button of calender Start=====
.calender-wrapper {
	.fc-button-primary {
		background: transparent;
		color: #191919;
		border-color: #191919;
	}
}
.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
	background-color: #191919;
	border-color: #191919;
}
// =========For week button of calender End=====
.modal-btn-wrapper {
	@media (max-width: 575px) {
		justify-content: center;
	}
	button {
		margin-left: 15px !important;
		@media (max-width: 575px) {
			margin: 0 8px 16px !important;
		}
	}
}
//===== For Booking Check In Modal S==
.login-time-model {
	border: solid 1px #191919;
	width: 68%;
	margin: 0 auto 10px;
	span {
		display: inline-block;
		min-width: 110px;
		padding: 3px 12px;
		background: #191919;
		color: #fff;
		margin-right: 8px;
	}
}
//===== For Booking Check In Modal E==
//===== For Notification Dropdown S==
.notification-dropdown {
	position: relative;
	&:after {
		display: none;
	}
	.noti-drop-icon {
		img {
			width: 24px;
			@media (max-width: 991px) {
				width: 25px;
			}
		}
		span {
			border: solid 1px #adadad;
			position: absolute !important;
			background: #adadad;
			width: 8px;
			height: 8px;
			bottom: 8px;
			&:after {
				display: none;
			}
		}
	}
	.dropdown-menu.dropdown-reset {
		left: auto;
		right: 0;
		min-width: 390px;
		padding-bottom: 0;
		padding-top: 10px;
		margin-top: 8px;
		@media (max-width: 767px) {
			right: -10px;
		}
		@media (max-width: 500px) {
			min-width: 335px;
		}
		@media (max-width: 450px) {
			right: -80px;
		}
		@media (max-width: 400px) {
			min-width: 295px;
		}
		@media (max-width: 350px) {
			min-width: 265px;
		}
		&:after {
			position: absolute;
			content: '';
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #fff;
			top: -29px;
			left: auto;
			right: 18px;
			transform: translateX(0);
			@media (max-width: 450px) {
				right: 88px;
			}
		}
		&:before {
			display: none;
		}
		.noti-list-wrapper {
			h3 {
				color: #333;
				margin: 0;
				font-size: 16px;
				font-weight: 600;
				text-align: center;
				text-transform: capitalize !important;
				@media (max-width: 767px) {
					font-size: 15px;
				}
			}
			button {
				@media (max-width: 767px) {
					font-size: 15px;
				}
			}
			ul {
				padding: 0;
				max-height: 350px;
				overflow: auto;
				margin-bottom: 0;
				li {
					list-style: none;
					a {
						display: flex;
						padding: 8px 15px;
						white-space: normal;
						font-size: 15px;
						color: #333;
						@media (max-width: 767px) {
							font-size: 14px;
						}
						img {
							width: 40px;
							height: 40px;
							border-radius: 50%;
							margin-right: 15px;
							object-fit: cover;
							object-position: center top;
						}
						p {
							margin: 0;
							color: #191919;
							line-height: 1.3;
							span {
								font-weight: 600;
							}
						}
						.notification-customer-name {
							width: 100%;
						}
						.notification-time {
							font-size: 13px;
							font-weight: 600;
							margin-top: 2px;
						}
						&:hover {
							background: #e7e7e7;
							color: #333 !important;
						}
					}
					&:last-child {
						a {
							border-bottom-right-radius: 6px;
							border-bottom-left-radius: 6px;
						}
					}
				}
				.active {
					a {
						background: #e7e7e7;
					}
				}
			}
		}
	}
	.view-all-wrapper {
		padding: 0 15px 12px;
		button {
			&:hover {
				color: #1a41ff;
			}
		}
	}
}
.header-wrapper.header-final-wrapper {
	.notification-dropdown {
		.dropdown-menu.dropdown-reset {
			@media (max-width: 450px) {
				right: -10px;
			}
			&:after {
				@media (max-width: 450px) {
					right: 18px;
				}
			}
		}
	}
}
.m-spl-57 {
	@media (max-width: 992px) {
		margin-left: 82px;
	}
	@media (max-width: 767px) {
		margin-left: 50px !important;
	}
	@media (max-width: 575px) {
		margin-left: 45px !important;
	}
	@media (max-width: 400px) {
		margin-left: 28px !important;
	}
}
//===== For Notification Dropdown E==
//===== For Shadow Animation S==
.pulse {
	box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
	animation: pulse 2s infinite;
	border-radius: 50%;
	text-align: center;
	padding: 2px 1px 2px 4px;
}
.pulse:hover {
	animation: none;
}
@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}
// For Admin Notification
.pulse-admin {
	box-shadow: 0 0 0 rgba(90, 90, 90, 0.4);
	animation: pulseAdmin 2s infinite;
	border-radius: 50%;
	text-align: center;
	padding: 2px 0 2px 4px;
}
.pulse-admin:hover {
	animation: none;
}
@-webkit-keyframes pulseAdmin {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(90, 90, 90, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(90, 90, 90, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(90, 90, 90, 0);
	}
}
@keyframes pulseAdmin {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(90, 90, 90, 0.4);
		box-shadow: 0 0 0 0 rgba(90, 90, 90, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(90, 90, 90, 0);
		box-shadow: 0 0 0 10px rgba(90, 90, 90, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(90, 90, 90, 0);
		box-shadow: 0 0 0 0 rgba(90, 90, 90, 0);
	}
}
//===== For Shadow Animation E==
// ==For Profile Link Modale S==
.disable-group-text.form-group {
	.input-group-text {
		background-color: #e9ecef;
		border: 1px solid #ced4da;
		cursor: not-allowed;
		&:hover {
			border-color: #ced4da;
		}
	}
}
.slug-wrapper {
	position: relative;
	align-items: flex-start;
	.btn-icon {
		position: absolute;
		top: 7px;
		right: -30px;
		z-index: 99;
		font-size: 22px;
		cursor: pointer;
		color: #6c7293;
		line-height: 19px;
	}
}
.suges-wrapper {
	h3 {
		font-size: 17px;
		font-weight: 600;
		margin: 8px 0 3px;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		li {
			min-width: 142px;
			padding-right: 15px;
			font-size: 15px;
			font-weight: 600;
			color: #6c7293;
			cursor: pointer;
			& > span {
				margin: 0 0 0 5px;
				font-size: 14px;
				font-weight: 500;
			}
		}
	}
}
.note-wrapper {
	color: #6c7293;
	font-size: 14px;
	margin-bottom: 8px;
	span {
		color: #101010;
		font-weight: 600;
	}
}
.close.show {
	display: block !important;
}
.studio-slug-wrap {
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}
.studio-slug-wrap {
	.form-control {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		height: 38px;
	}
}
.error-slug {
	font-size: 14px;
	color: red;
	margin-bottom: 0;
}
.prof-btn-wrap {
	justify-content: space-between;
	.quick-action-link {
		width: 100%;
	}
	.common-btn.btn.outline-btn {
		min-width: 112px;
		padding: 8px 16px;
		font-size: 14px;
		margin-right: 20px;
		&:last-child {
			margin: 0;
		}
	}
}
// ==For Profile Link Modale E==
// ==For Color Denotes S========
.color-denot-wrapper {
	ul {
		list-style: none;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 1%;
		justify-content: center;
		li {
			font-weight: 600;
			padding-right: 15px;
			display: inline-flex;
			span {
				width: 16px;
				height: 16px;
				background: #f3aa59;
				vertical-align: middle;
				display: inline-block;
				margin-right: 8px;
			}
		}
	}
}
.bg-event {
	background: #fa4b85 !important;
}
.bg-group-class {
	background: #54c2dc !important;
}
.bg-service {
	background: #f3aa59 !important;
}
.bg-visual {
	background: #479a25cc !important;
}

// ==For Color Denotes E========
// ==For Tooltip Issue Solve S========
.tooltip-wrapper {
	.card-header {
		border: 0;
		padding-bottom: 0;
	}
	.table-responsive-wrap {
		padding-top: 20px;
	}
}
// ==For Tooltip Issue Solve E========
// ==For Responsive S========
.total-tabs-wrapper {
	.title {
		br {
			display: none;
			@media (max-width: 767px) {
				display: block;
			}
		}
	}
}
@media (max-width: 767px) {
	.total-tabs-wrapper {
		margin: 0 -5px;
		.grid-margin.col-4 {
			margin-bottom: 10px;
			padding: 0 5px;
			@media (max-width: 575px) {
				margin-bottom: 6px;
				padding: 0 3px;
			}
		}
		.card-section {
			.card-body {
				padding: 6px 12px;
				@media (max-width: 575px) {
					padding: 6px;
				}
				.sales-section {
					align-items: flex-start !important;
					@media (max-width: 575px) {
						padding: 0 !important;
					}
					> div:first-child {
						width: calc(100% - 66px);
						@media (max-width: 575px) {
							width: calc(100% - 50px);
						}
						@media (max-width: 400px) {
							width: calc(100% - 24px);
						}
						p {
							font-size: 14px;
							line-height: 1.4;
							@media (max-width: 575px) {
								font-size: 13px;
							}
						}
					}
					.sales-count {
						font-size: 14px;
						width: 66px;
						@media (max-width: 575px) {
							font-size: 13px;
							width: 50px;
						}
						@media (max-width: 400px) {
							width: 36px;
						}
					}
				}
			}
		}
	}
	.prof-btn-wrap {
		.quick-action-link {
			text-align: center;
			b {
				word-break: break-all;
			}
		}
		.common-btn.btn.outline-btn {
			min-width: 80px;
			padding: 6px 10px;
			font-size: 13px;
			margin-right: 10px;
		}
	}
	.modal-custom-wrapper {
		.modal-dialog {
			padding: 0 12px;
			.modal-content {
				.modal-header {
					.modal-title {
						font-size: 18px !important;
					}
				}
			}
		}
	}
	.fc-toolbar {
		h2 {
			font-size: 15px !important;
		}
	}
	.fc-left,
	.fc-right {
		font-size: 13px !important;
	}
	.navbar-menu-wrapper {
		.dropdown-menu {
			.noti-list-wrapper {
				h3,
				button {
					font-size: 14px;
				}
				ul {
					li {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}
		.navbar-nav {
			.filter-dropdown {
				.dropdown-toggle {
					background-color: #f0f3ff !important;
					img.active-img {
						opacity: 1 !important;
					}
				}
			}
		}
	}
}
@media (max-width: 620px) {
	.fc-toolbar h2 {
		font-size: 14px !important;
	}
	.fc-left,
	.fc-right {
		font-size: 12px !important;
	}
}
@media (max-width: 575px) {
	.slug-wrapper {
		display: block;
		.input-group-prepend {
			margin: 0;
			.input-group-text {
				width: 100%;
				border-radius: 4px;
				font-size: 14px;
				border-bottom: 0;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		.studio-slug-wrap {
			flex: 0 0 100%;
			width: 100%;
			border-radius: 4px;
			font-size: 14px;
			input {
				font-size: 14px !important;
				height: 33px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-top: 0;
			}
		}
		.btn-icon {
			right: -30px;
			top: auto;
			bottom: -1px;
			span {
				svg {
					width: 22px;
				}
			}
		}
	}
	.prof-btn-wrap {
		justify-content: center;
		.quick-action-link {
			margin-bottom: 10px;
		}
		> div {
			margin: 0;
		}
		.common-btn.btn.outline-btn {
			min-width: auto;
			padding: 6px 10px;
			font-size: 12px;
			margin: 0 6px 12px;
			&:last-child {
				margin: 0 6px 12px;
			}
		}
	}
	.modal-custom-wrapper {
		.modal-dialog {
			padding: 0;
			.modal-content {
				.modal-header {
					.modal-title {
						font-size: 17px !important;
					}
				}
			}
		}
	}
}
@media (max-width: 500px) {
	.fc-toolbar {
		//flex-wrap: wrap;
		//text-align: center;
		.fc-left,
		.fc-center,
		.fc-right {
			width: 100%;
			margin-bottom: 12px;
		}
		.fc-right {
			margin-bottom: 0px;
		}
	}
	.color-denot-wrapper {
		ul {
			// flex-wrap: nowrap;
			margin: 0px 0 15px;
			li {
				padding-right: 8px;
				margin-bottom: 6px;
				font-size: 13px;
				@media (max-width: 400px) {
					font-size: 12px;
				}
				&:last-child {
					padding: 0;
				}
				span {
					width: 10px;
					height: 10px;
					margin-right: 5px;
					margin-top: 3px;
				}
			}
		}
	}
	.fc table {
		font-size: 14px;
	}
	.fc-toolbar h2 {
		font-size: 16px !important;
	}
	.fc-button {
		font-size: 14px !important;
	}
	.color-denot-wrapper {
		ul {
			li {
				font-size: 14px;
			}
		}
	}
	.navbar-menu-wrapper {
		.dropdown-menu {
			.noti-list-wrapper {
				h3,
				button {
					font-size: 14px;
				}
				ul {
					li {
						a {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 360px) {
	.color-denot-wrapper {
		ul {
			li {
				font-size: 12px;
			}
		}
	}
}
// ==For Responsive E========
// ==For Extra S========
.main-panel {
	.content-wrapper {
		padding-bottom: 50px !important;
	}
}
.form-check {
	.form-check-input {
		top: 50%;
		margin-top: 0;
		transform: translateY(-50%);
		// z-index: 9999;
	}
}
.flatpickr-calendar.open {
	z-index: 9999 !important;
}
.save-btn-wrap {
	button {
		width: auto !important;
	}
}
body {
	.swal2-popup {
		.swal2-styled.swal2-confirm {
			@media (max-width: 991px) {
				&:hover {
					color: #788cea;
					background-color: rgba(93, 120, 255, 0.18) !important;
				}
			}
		}
	}
}
.two-btn-wrapper {
	margin-bottom: 5px;
	button {
		&:last-child {
			margin-left: 20px;
		}
		@media (max-width: 575px) {
			display: block;
			margin: 0 auto 15px !important;
			&:last-child {
				margin: 15px auto 0;
			}
		}
	}
}
// .rotate-image{
// 	transform: rotate(-85deg) !important;
// }
.noti-list-wrapper {
	.view-all-wrapper {
		border-bottom: solid 1px #e7e7e7;
	}
}
.content-wrapper {
	.notification-list-wrapper {
		box-shadow: none;
	}
}
// ==For Extra E========
// ==For New Dropdwon(Notification & User Dropdown) S========
.navbar {
	.navbar-menu-wrapper {
		.navbar-nav {
			.nav-profile {
				.notification-new-dropdown {
					.dropdown-toggle {
						padding: 0;
						&:after {
							display: none;
						}
					}
					.dropdown-menu {
						left: auto !important;
						transform: none !important;
						top: 100% !important;
						right: -13px;
						margin-top: 13px !important;
						@media (max-width: 380px) {
							right: -60px;
						}
						&:before {
							right: 10px !important;
							@media (max-width: 575px) {
								right: 8px !important;
							}
							@media (max-width: 380px) {
								right: 55px !important;
							}
						}
					}
				}
				.user-dropdown-new {
					left: auto !important;
					transform: none !important;
					top: 100% !important;
				}
			}
		}
	}
}
// ==For New Dropdwon(Notification & User Dropdown) E========
.react-html5-camera-photo {
	// position: fixed;
	// z-index: -1;
	// opacity: 0;
}
// ==For Cropper S========
.cropper-modal-wrapper {
	.modal-dialog {
		max-width: 600px;
		@media (max-width: 767px) {
			max-width: 500px;
		}
		@media (max-width: 575px) {
			max-width: 500px;
		}
		.modal-title {
			font-family: 'gilroysemibold';
			color: #191919;
			font-size: 18px;
		}
	}
	.cropper-inner {
		display: flex;
		justify-content: center;
	}
	.modal-footer {
		.btn {
			font-size: 15px;
			padding: 10px 20px;
			min-width: 112px;
			background: #1f73b7;
			font-family: 'gilroysemibold';
			border: solid 1px #1f73b7;
			box-shadow: none;
		}
		.outline-modal-btn {
			background: transparent;
			border: solid 1px #191919;
			color: #191919;
			box-shadow: none;
		}
	}
}
// ==For Cropper E========
@media only screen and (orientation: landscape) {
	.navbar-menu-wrapper {
		.dropdown-menu {
			.noti-list-wrapper {
				ul {
					max-height: calc(100vh - 110px) !important;
					overflow: auto !important;
				}
			}
		}
		.navbar-nav {
			.filter-dropdown {
				.dropdown-reset {
					.filter-content-listing {
						max-height: calc(100vh - 175px) !important;
						overflow: auto !important;
					}
				}
			}
		}
	}
	#launcher {
		z-index: 999 !important;
	}
}
