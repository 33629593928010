@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fullcalendar/common/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/list/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/timeline/main.css";
@import "~@fullcalendar/resource-timeline/main.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
// @import '~react-datepicker/dist/react-datepicker.css';
@import "~rc-pagination/assets/index.css";
//@import './assets/css/styles.scss';
@import "~flatpickr/dist/themes/material_green.css";
@import "./styles/components/_all.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  overflow-x: hidden;
  line-height: normal;
  background: #efeff5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
// }

.no-result-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  min-height: 400px;
  h5 {
    color: #6c7293;
    margin-bottom: 0;
    padding-top: 10px;
    font-size: 18px;
  }
  .no-service-data {
    position: relative;
    padding-top: 10px;
    width: 40%;
    margin: 0 auto;
    border-top: 1px solid #ccc;
    .btn {
      font-size: 15px;
      color: #6c7293;
      justify-content: space-evenly;
      align-items: center;
      display: inline-flex;
      line-height: 100%;
      position: relative;
      font-weight: 500;
      &:hover,
      &:focus {
        text-decoration: none;
        color: #3856ff;
      }
    }
  }
}

.custom-no-result-block-height {
  .no-result-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    min-height: 300px;
    h5 {
      color: #6c7293;
      margin-bottom: 0;
      padding-top: 10px;
      font-size: 18px;
    }
    .no-service-data {
      position: relative;
      padding-top: 10px;
      width: 40%;
      margin: 0 auto;
      border-top: 1px solid #ccc;
      .btn {
        font-size: 15px;
        color: #6c7293;
        justify-content: space-evenly;
        align-items: center;
        display: inline-flex;
        line-height: 100%;
        position: relative;
        font-weight: 500;
        &:hover,
        &:focus {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
}

.error {
  color: red;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

#video-confrence {
  height: 100%;
}

.screen-present-section video:not(:first-child) {
  height: 0px;
}

.default-font-weight {
  font-weight: 100;
}
.main-title{
  font-size: 18px;
  font-weight: 600;
  color: #191919;
  img{
      vertical-align: text-bottom;
      width: 20px;
      margin-right: 10px;
  }
}
.sub-title{
  font-size: 18px;
  color: #2E2E2E;
  font-weight: 600;
}

.common-btn{
  display: inline-block;
  min-width: 160px;
  background: #000;
  font-size: 14px;
  color: #fff;
  text-align: center;
  padding: 10px 15px;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid #000000;
  border-radius:0;
  line-height: initial;
  &:focus,
  &:hover{
      border-color: #000;
      background: transparent;
      color: #000;
  }
}

.common-btn.outline-btn{
  background: transparent;
  color: #000;
  &:focus,
  &:hover{
      background: #000;
      color: #fff;
  }
}

.search-filed{
  border-color: #eaeaea;
  width: 220px;
  height: 35px;
  padding-right: 30px;
   background: url(./assets/img/icon_search.png) no-repeat scroll right 12px center;
  background-size: 17px auto;
}
#RangeComponent .input-range__label--min span.input-range__label-container:before,
#RangeComponent .input-range__label--max span.input-range__label-container:before {
  content: "\0024";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #000;
  font-size: 13px;
  padding-right: 0.1em;
  /* position: absolute; */
  top: 10px;
  font-size: 16px;
  left: 0;
  font-weight: bold;
}
// ===================== Custom Modal Start====================
.modal-custom-wrapper{
  .modal-dialog{
      @media (min-width: 576px){
          max-width: 600px;
      }
      .modal-content{
          box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          padding: 10px 20px;
          .modal-header{
              background: transparent;
              border-color: #EFEFEF;
              .modal-title{
                  font-size: 20px;
                  color: #000;
                  font-weight: 600;
              }
              .close{
                  display: none;
              }
          }
          .modal-body{
              font-size: 15px;
              color: #000;
              line-height: 1.6;
          }
          .modal-footer{
              border:0;
          }
      }
  }
}
// ===================== Custom Modal End====================