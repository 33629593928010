.wrapper-manual{
	padding: 20px 0;
}
.visual-left-section{
	display: flex;
	justify-content: space-between;
	@media (max-width: 575px){
		display: block;
	}
}
.host-img{
	img{
		width: 80px;
		height: 80px;
		object-fit: cover;
		object-position: center top;
		border-radius: 50%;	 
	}
}
.live-wrapper{
	margin-top: 8px;
	border:solid 1px #114699;
    padding: 2px 10px;
    color: #114699;
    display: inline-block;
    font-family: "gilroysemibold";
    .live-outline{
    	display: inline-block;
	    width: 12px;
	    line-height: 8px;
	    height: 12px;
	    background: #ffffff;
	    border-radius: 50%;
	    text-align: center;
	    border: solid 1px #114699;
		margin-right: 2px;
		span{
		   	display: inline-block;
		    background: #114699;
		    width: 8px;
		    height: 8px;
		    border-radius: 50%;
		}
    }
}
.end-session{
	display: flex;
	align-items: center;
	padding-left: 30px;	
	@media (max-width: 767px){
		padding-left: 12px;
	}	
	@media (max-width: 575px){
		padding-left:0;
		padding-top: 22px;
	}
	.video-timer{
		margin-right: 16px !important;
	}
}
.yet-session{
	text-align: center;
	margin-bottom: 0;
	padding:6px 0;
}
.attendee-inner{
	overflow: auto;
	height: 445px;
	padding: 20px;
	.table{
		margin-bottom: 0;
		tr{
			background: transparent;
			box-shadow: none;
			th,
			td{
				border:1px solid rgba(204, 204, 204, 0.56);
				padding: 12px 20px;
			}
			th{
				background: transparent;
				font-weight: 600;
			}
			th:first-child{
				width: 55px;
				padding:12px 10px;
			}
			td{
				.audience-card{
					border:0;
					padding: 0;
				}
			}
			.w-log{
				width: 230px;
				text-align: center;
			}
		}
	}
	li{ 
		width:25%;
		@media (max-width: 1200px){
			width: 33.33%;
		}
		@media (max-width: 991px){
			width: 50%;
		}
		@media (max-width: 575px){
			width: 100%;
		}
		.audience-card{
			padding: 10px 12px !important;
			height: 100%;
			img{
				width: 42px !important;
				height: 42px !important;
			}
		}
	}
}