/* ==================== custom-scrollbar Css ================ */
.custom-scrollbar::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
.custom-scrollbar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
	// border-radius: 7px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #c1c1c1;
	// border-radius: 7px;
}
/* ==================== custom-scrollbar Css ================ */
.accepted-card {
	display: flex;
	.payment-card {
		height: 100px;
		width: 100px;
		background: #fff;
		/* box-shadow: -2px 4px 6px 2px #f5f4f4; */
		margin: 10px 0 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}
