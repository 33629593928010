.Toastify,
.Toastify__toast {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
	font-family: 'Open Sans', sans-serif !important;
}
.Toastify__toast-container {
	width: 380px !important;
	@media (max-width:767px){
		width: 280px !important;
	}
}
.Toastify .Toastify__toast--success {
	// box-shadow: 0 0 10px rgba(0, 0, 0, 0.4) !important;
	box-shadow: 0px 5px 12px 1px rgba(0, 0, 0, 0.28) !important;
	background-color: #fff !important;
	border-radius: 6px !important;
	color: #191919;
}
.Toastify__toast.Toastify__toast--success .Toastify__toast-body {
	position: relative;
	padding-left: 50px;
	text-align: center;
	font-weight: 600;
	white-space: pre-line;
}
.Toastify__toast.Toastify__toast--success .Toastify__toast-body::before {
	content: '';
	background-image: url('../../../assets/img/check-toaster.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 40px;
	position: absolute;
	left: 0px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 11;
	width: 40px;
	height: 40px;
}
.Toastify__toast--error {
	// background: #e74c3c;
	// box-shadow: 0 0 10px rgba(0, 0, 0, 0.4) !important;
	box-shadow: 0px 5px 12px 1px rgba(0, 0, 0, 0.28) !important;
	background-color: #fff !important;
	border-radius: 6px !important;
	color: #191919;
}
.Toastify__toast.Toastify__toast--error .Toastify__toast-body {
	position: relative;
	padding-left: 50px;
	text-align: center;
	font-weight: 600;
}
.Toastify__toast.Toastify__toast--error .Toastify__toast-body::before {
	content: '';
	background-image: url('../../../assets/img/error-toaster.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 40px;
	position: absolute;
	left: 0px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 11;
	width: 40px;
	height: 40px;
}
.Toastify .Toastify__toast {
	padding: 10px;
	border-radius: 3px;
	min-width: 200px;
	font-size: 16px;
	@media (max-width: 767px){
		font-size: 14px;
		top: 8px;
		left: 8px;
	}
}
.Toastify .Toastify__progress-bar {
	height: 3px;

}
.Toastify .Toastify__close-button {
	font-family: sans-serif !important;
	position: absolute;
	top: 0;
	right: 0;
	justify-content: center;
	width: 10px;
	height: 10px;
	margin: 8px;
	padding: 0px;
	border: none;
	font-size: 0px;
	line-height: 20px;
	cursor: pointer;
	overflow: hidden;
	background-image: url('../../../assets/img/cancel-black.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 10px;
	&:hover {
		background-image: url('../../../assets/img/cancel-black.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 10px;
	}
}
