.page-body-wrapper {
  padding-top: 60px;
  min-height: calc(100vh - 60px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}
.main-panel {
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
  // width: calc(100% - 160px);
  // margin-left: 210px;
  min-height: calc(100vh - 60px);
  width: 100%;
  padding-left: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media (max-width: 1100px) {
    padding-left: 0px;
  }
  .content-wrapper {
    background: #EEEEF3;
    padding: 30px;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    @media (max-width: 575px) {
      padding: 15px;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
.page-header {
  margin-bottom: 15px;
  .page-title {
    color: #343a40;
    font-size: 1.125rem;
    margin-bottom: 0;
  }
  .breadcrumb {
    padding: 0px;
    margin-bottom: 0rem;
    background-color: transparent;
    border-radius: 0px;
    @media (max-width: 500px) {
      margin-top: 10px;
    }
    .breadcrumb-item {
      a {
        color: #a3a6bd;
        .breadcrumb-icon {
          position: relative;
          top: -3px;
          img {
            width: 16px;
            margin-right: 10px;
          }
        }
      }
      &.active {
        color: #114699;
      }
    }
  }
}
.grid-margin {
  margin-bottom: 2.5rem;
}
.page-header-wrap {
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 568px) {
      display: block;
    }
    .dropdown {
      .btn-heighlight {
        font-weight: 600;
      }
      button {
        border-radius: 30px;
        border-color: transparent;
        background: #fff;
        color: #aaadb8;
        &:focus,
        &:hover,
        &:active {
          background: #fff;
          border-color: #fff;
          color: #aaadb8;
        }
      }
    }
    .heading-text {
      font-size: 22px;
      font-weight: 500;
      color: #595d6e;
    }
    .page-title svg {
      margin-right: 20px;
    }
  }
}
.error_page {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }
  h2 {
    font-weight: 600;
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
    color: #888;
  }
}
