.swal2-container .swal2-popup {
	background: #fff;
}
.swal2-container .swal2-popup .swal2-title {
	// position: relative;
	// max-width: 100%;
	// margin: 0 0 10px;
	// padding: 10px;
	// color: #061534;
	// font-size: 20px !important;
	// font-weight: 500;
	// text-align: start;
	// text-transform: none;
	// word-wrap: break-word;
	color: #343a40;
	font-size: 20px !important;
	letter-spacing: 0px;
}
.swal2-header {
	.swal2-icon {
		&.swal2-warning {
			border-color: #ffb822;
			color: #ffb822;
			margin: 0px auto 25px;
			width: 50px;
			height: 50px;
			border-width: 3px;
		}
		&:before {
			height: 100%;
			font-size: 45px;
		}
	}
}
.swal2-content {
	// color: #002a5a;
	font-size: 14px !important;
	color: #343a40 !important;
}
.swal2-actions {
	margin: 2.25em auto 0px;
	.swal2-styled {
		margin: 0.3125em;
		margin-bottom: 0px;
		padding: 10px 30px;
		box-shadow: none;
		font-weight: 400;
		font-size: 14px !important;
	}
}

.swal2-popup .swal2-styled.swal2-confirm {
	background-color: #5867dd !important;
	background: rgba(93, 120, 255, 0.18) !important;
	border-color: transparent;
	color: #788cea;
	border: 0;
	border-radius: 0.25em;
	background: initial;
	font-size: 1.0625em;
	&:hover {
		color: #fff;
		background-color: #5867dd !important;
	}
}

.swal2-popup .swal2-styled.swal2-cancel {
	background-color: #eb5757 !important;
	background: rgba(253, 57, 122, 0.18) !important;
	border-color: transparent;
	color: #fd397a;
	border: 0;
	border-radius: 0.25em;
	background: initial;
	font-size: 1.0625em;
	margin-top:0;
	&:hover {
		color: #fff;
		background-color: #fd397a !important;
	}
}
